.skip-nav {
  position: absolute;
  left: -251px;
  z-index: 3000;
  background-color: $whiteColor100;
  border: 3px solid $secondaryColor80;

  ul {
    margin: 15px 15px 15px 30px;
    padding: 0;
  }

  li {
    text-align: right;
  }

  a {
    display: inline-block;
    width: 200px;
    text-align: left;
    color: $secondaryColor80;
  }

  a:focus {
    margin-left: 251px;
    outline: none;
    text-decoration: underline;
    color: $secondaryColor80;
  }
}

// ------------------CONTRAST------------------
.contrast {
  .skip-nav {
    background-color: $contrastBlackColor;
    border-color: $contrastYellowColor;

    a {
      color: $contrastYellowColor;
    }

    a:focus {
      color: $contrastWhiteColor;
    }
  }
}
