$bannersTickerHeight: 3rem;

.banners-ticker {
  display: block;
  overflow: hidden;
  width: 100%;
  height: $bannersTickerHeight;
  color: $whiteColor100;
  background-image: $primaryColor80;

  .container {
    position: relative;
    height: $bannersTickerHeight;
    padding-right: var(--row-gutter);
    padding-left: calc(var(--row-gutter) + #{$bannersTickerHeight});
  }

  &-play,
  &-pause {
    position: absolute;
    top: 50%;
    left: var(--row-gutter);
    z-index: 10;
    height: 100%;
    cursor: pointer;
    transform: translateY(-50%);
    color: $whiteColor100;
    background-color: transparent;
    border: none;
    aspect-ratio: 1 / 1;
    @include transition(box-shadow, background-color, color, border-color);

    &.hidden {
      visibility: hidden;
    }

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-family: $fontIconFamily;
      @include bodyLg();
      @include transition(box-shadow, background-color, color, border-color);
    }

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      width: 1px;
      height: $bannersTickerHeight / 2;
      transform: translateY(-50%);
      background-color: $whiteColor100;
    }

    &:hover,
    &:focus {
      background-color: $secondaryColor80;
    }
  }

  &-play::before {
    content: '\f04b';
  }

  &-pause::before {
    content: '\f04c';
  }

  &-list {
    display: flex;
    align-items: center;
    height: 100%;
  }

  &-mask {
    height: 100%;
  }

  &-text {
    white-space: nowrap;
    text-decoration: none;
    color: $whiteColor100;
    @include h200();

    &:is(a, button) {
      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }
}

// ------------------CONTRAST------------------
.contrast {
  .banners-ticker {
    color: $contrastYellowColor;
    background-color: $contrastBlackColor;
    background-image: none;
    border-top: 1px solid $contrastYellowColor;

    .container {
    }

    &-play,
    &-pause {
      height: calc(100% - 2px);
      color: $contrastYellowColor;
      background-color: $contrastBlackColor;

      &::after {
        background-color: $contrastYellowColor;
      }

      &:hover,
      &:focus {
        color: $contrastBlackColor;
        background-color: $contrastYellowColor;
      }
    }

    &-text {
      color: $contrastYellowColor;

      &:is(a, button) {
        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }
    }
  }
}
