:where(.btn) {
  display: inline-block;
  margin: 0;
  cursor: pointer;
  user-select: none;

  outline: none;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;
  text-transform: none;

  font-family: $fontFamily;
  font-weight: 700;

  color: $darkTextColor;

  background-color: $grayColor60;
  border: none;
  border-radius: $roundedBorderRadius;
  -webkit-tap-highlight-color: transparent;

  @include transition(box-shadow, background-color, background-image, color, border-color);

  &:disabled,
  &.disabled {
    cursor: default;
    pointer-events: none;
    color: $grayColor60;
    background-color: $grayColor5;
  }
}

:where(.btn.btn-small) {
  @include bodySm();

  &:not(.btn-icon) {
    padding: 8px 16px;
  }

  &.btn-icon {
    padding: 8px;
  }
}

:where(.btn:not(.btn-icon, .btn-small, .btn-large)) {
  padding: 10px 20px;
}

:where(.btn.btn-icon:not(.btn-small, .btn-large)) {
  padding: 10px;
}

:where(.btn.btn-large) {
  @include bodyLg();

  &:not(.btn-icon) {
    padding: 12px 24px;
  }

  &.btn-icon {
    padding: 12px;
  }
}

:where(.btn.btn-primary) {
  color: $lightTextColor;
  background-image: $primaryColor80;

  &:hover:not(:disabled, .disabled, .btn-loading),
  &:focus:not(:disabled, .disabled, .btn-loading) {
    color: $lightTextColor;
    background-image: $primaryColor100;
  }

  &.inverted {
    color: $lightTextColor;
    background-image: $primaryInvertedColor80;

    &:hover:not(:disabled, .disabled, .btn-loading),
    &:focus:not(:disabled, .disabled, .btn-loading) {
      color: $lightTextColor;
      background-image: $primaryInvertedColor100;
    }
  }
}

:where(.btn.btn-secondary) {
  color: $darkTextColor;
  background-color: $whiteColor100;
  border: 1px solid $grayColor100;

  &:hover:not(:disabled, .disabled, .btn-loading),
  &:focus:not(:disabled, .disabled, .btn-loading) {
    color: $darkTextColor;
    background-color: $grayColor10;
  }
}

:where(.btn.btn-destructive-primary) {
  color: $whiteColor100;
  background-color: $errorColor50;
  border: 1px solid $errorColor50;

  &:hover:not(:disabled, .disabled, .btn-loading),
  &:focus:not(:disabled, .disabled, .btn-loading) {
    color: $whiteColor100;
    background-color: $errorColor100;
    border-color: $errorColor100;
  }
}

:where(.btn.btn-destructive-secondary) {
  color: $errorColor50;
  background-color: $errorColor0;
  border: 1px solid $errorColor0;

  &:hover:not(:disabled, .disabled, .btn-loading),
  &:focus:not(:disabled, .disabled, .btn-loading) {
    color: $errorColor50;
    background-color: $errorColor0;
    border-color: $errorColor50;
  }
}

:where(.btn.btn-success-primary) {
  color: $whiteColor100;
  background-color: $successColor50;
  border: 1px solid $successColor50;

  &:hover:not(:disabled, .disabled, .btn-loading),
  &:focus:not(:disabled, .disabled, .btn-loading) {
    color: $whiteColor100;
    background-color: $successColor100;
    border-color: $successColor100;
  }
}

:where(.btn.btn-success-secondary) {
  color: $successColor50;
  background-color: $successColor0;
  border: 1px solid $successColor0;

  &:hover:not(:disabled, .disabled, .btn-loading),
  &:focus:not(:disabled, .disabled, .btn-loading) {
    color: $successColor50;
    background-color: $successColor0;
    border-color: $successColor50;
  }
}

:where(.btn.btn-loading) {
  position: relative;
  cursor: default;
  pointer-events: none;
  opacity: 1;
  color: transparent !important;
  transition: all 0s linear, opacity $transitionDuration $transitionTimingFunction;
  --size: 20px;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: var(--size);
    height: var(--size);
    border: 4px solid rgba(0, 0, 0, 0.15);
    border-radius: $roundedBorderRadius;
    translate: -50% -50%;
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: var(--size);
    height: var(--size);
    border-width: 4px;
    border-style: solid;
    border-color: $whiteColor100 transparent transparent;
    border-radius: $roundedBorderRadius;
    animation: animation-spin 0.6s linear infinite;
    translate: -50% -50%;
  }
}

:where(.btn-group) {
  display: inline-flex;
  gap: 0;
  flex-direction: row;

  .btn {
    border-radius: 0;

    &:first-child {
      border-top-left-radius: $borderRadius;
      border-bottom-left-radius: $borderRadius;
    }

    &:last-child {
      border-top-right-radius: $borderRadius;
      border-bottom-right-radius: $borderRadius;
    }
  }
}

// ------------------CONTRAST------------------
.contrast {
  .btn {
    color: $contrastYellowColor;
    background-color: $contrastBlackColor;

    &.btn-primary {
      color: $contrastYellowColor;
      background-color: $contrastBlackColor;
      background-image: none;
      border: 1px solid $contrastYellowColor;

      &:hover:not(:disabled, .disabled, .btn-loading),
      &:focus:not(:disabled, .disabled, .btn-loading) {
        color: $contrastBlackColor;
        background-color: $contrastYellowColor;
        background-image: none;
      }
    }

    &.btn-secondary {
      color: $contrastBlackColor;
      background-color: $contrastYellowColor;

      &:hover:not(:disabled, .disabled, .btn-loading),
      &:focus:not(:disabled, .disabled, .btn-loading) {
        color: $contrastBlackColor;
        background-color: $contrastWhiteColor;
      }
    }

    &.btn-loading {
      color: transparent !important;

      &::before {
        border-color: rgba(0, 0, 0, 0.15);
      }

      &::after {
        border-color: $contrastYellowColor transparent transparent;
      }
    }

    &:disabled,
    &.disabled {
      color: $contrastWhiteColor;
      background-color: $contrastBlackColor;
      border: $contrastWhiteColor;
    }
  }
}
