.tile {
  display: grid;
  height: 100%;
  border-radius: $borderRadius;
  box-shadow: $shadowSm;
  @include transition(box-shadow, background-color, color, border-color);

  @media #{$md-min-width} {
    gap: 24px;
    grid-template-columns: 180px 1fr;
  }

  &.hover {
    text-decoration: none;
    box-shadow: $shadowMd;
  }

  &:has(.tile-content-wrapper:hover, .tile-content-wrapper:focus) {
    text-decoration: none;
    box-shadow: $shadowMd;
  }

  &:is(a, button) {
    &:hover,
    &:focus {
      text-decoration: none;
      box-shadow: $shadowMd;
    }
  }

  &.vertical {
    display: flex;
    gap: 0;
    flex-direction: column;

    .tile-image {
      height: unset;
      border-radius: $borderRadius $borderRadius 0 0;
    }

    .tile-content {
      padding: 24px 16px;
    }
  }

  &-image {
    position: relative;
    width: 100%;
    height: 100%;
    border: 1px solid $secondaryColor10;
    object-fit: cover;

    &-wrapper {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 0;
        width: 100%;
        height: 100%;
        transform: translate(-50%, -50%);
        background-image: var(--tile-background-image-url);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 20%;
      }

      &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 1;
        background-repeat: no-repeat;
        background-position: center;
        translate: -50% -50%;
      }

      &.video::after {
        content: '';
        width: var(--size);
        height: var(--size);
        background-image: url("#{$assetsPath}/images/play_circle.svg");
        --size: 52px;
      }
    }
  }

  &-pinned {
    position: absolute;
    top: 15px;
    left: 15px;
    z-index: 2;
    display: flex;
    align-items: center;
    gap: 8px;
    @include bodySm();

    &-pin {
      font-size: 1.5em;
      color: $lightTextColor;
    }

    &-text {
      color: $lightTextColor;
      background-color: $secondaryColor100;
      border: 1px solid $lightTextColor;
      border-radius: $borderRadius;
    }
  }

  &-content {
    display: flex;
    gap: 12px;
    flex-direction: column;
    height: 100%;
    padding: 16px;

    @media #{$sm-min-width} {
      padding: 32px;
    }

    &-wrapper {
      display: flex;
      gap: 16px;
      flex-direction: column;
      flex-grow: 1;
    }
  }

  &-date {
    margin-bottom: 2px;
    color: $darkTextColor;
  }

  &-metadata {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    margin-bottom: 12px;

    &-item {
    }
  }

  &-title {
    color: $darkTextColor;
    @include h300();
  }

  &-text {
    color: $darkTextColor;
    @include bodyMd();
  }

  .js-point-url {
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  &-more {
    color: $darkTextColor;
    @include h100();
  }
}

// ------------------CONTRAST------------------
.contrast {
  .tile {
    color: $contrastYellowColor;
    background-color: $contrastBlackColor;
    border: 1px solid $contrastYellowColor;
    box-shadow: none;

    &.hover {
      text-decoration: none;
      color: $contrastWhiteColor;
      background-color: $contrastBlackColor;
      border: 1px solid $contrastWhiteColor;
      box-shadow: none;
    }

    &:has(.tile-link:hover, .tile-link:focus) {
      text-decoration: none;
      color: $contrastWhiteColor;
      background-color: $contrastBlackColor;
      border: 1px solid $contrastWhiteColor;
      box-shadow: none;
    }

    &:is(a, button) {
      &:hover,
      &:focus {
        text-decoration: none;
        color: $contrastWhiteColor;
        background-color: $contrastBlackColor;
        border: 1px solid $contrastWhiteColor;
        box-shadow: none;
      }
    }

    &-image {
      border: none;
    }

    &-date,
    &-title,
    &-text,
    &-more {
      color: inherit;
    }

    .js-point-url {
      color: inherit;

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }
}
