.polls-show-v1 {
  position: relative;

  &-wrapper {
    padding: 48px 16px;
    background-color: $grayColor0;
    border-radius: $borderRadius;
    box-shadow: $shadowSm;

    @media #{$sm-min-width} {
      padding: 48px;
    }
  }

  &-question {
    margin-bottom: 16px;
    text-wrap: balance;
    color: $grayColor100;
    @include h300();
  }

  &-form {
    display: grid;
    justify-content: start;
    gap: 16px;
    grid-template-columns: 1fr;

    &-fieldset {
      margin: 0;
      padding: 0;
      border: none;
    }

    .form-radio {
      width: 100%;

      @media #{$sm-min-width} {
        width: 75%;
      }
    }

    button[type="submit"] {
      justify-self: start;
    }
  }
}

// ------------------CONTRAST------------------
.contrast {
  .polls-show-v1 {
    &-wrapper {
      background-color: $contrastBlackColor;
      border: 1px solid $contrastYellowColor;
    }

    &-question {
      color: $contrastYellowColor;
    }
  }
}
