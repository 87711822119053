.jumbotrons-slide-v1 {
  padding: 64px 0;

  &-item {
    position: relative;
    overflow: hidden;
    padding: 48px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: $borderRadius;
    box-shadow: $shadowSm;

    &::before {
      content: "";
      position: absolute;
      inset: 0;
      z-index: 0;
      opacity: 0.4;
      background-image: linear-gradient(90deg, rgb(245, 184, 71) 0%, rgb(0, 0, 0) 100%);
      background-repeat: no-repeat;
    }
  }

  &-content {
    position: relative;
    display: grid;
    gap: 12px;
    color: $lightTextColor;

    &.halfWidthLeft {
      @media #{$md-min-width} {
        width: 50%;
      }
    }

    &.halfWidthRight {
      @media #{$md-min-width} {
        width: 50%;
        margin-left: auto;
      }
    }

    &.textAlignLeft {
      text-align: left;

      .jumbotrons-slide-v1-buttons {
        text-align: left;
      }
    }

    &.textAlignCenter {
      text-align: center;

      .jumbotrons-slide-v1-buttons {
        text-align: center;
      }
    }

    &.textAlignRight {
      text-align: right;

      .jumbotrons-slide-v1-buttons {
        text-align: right;
      }
    }
  }

  &-heading {
    @include h500();

    @media #{$md-min-width} {
      @include h600();
    }
  }

  &-subheading {
    @include h400();

    @media #{$md-min-width} {
      @include h500();
    }
  }

  &-text {
    @include h200();

    @media #{$md-min-width} {
      @include h300();
    }
  }

  &-buttons {
    margin-top: 12px;

    .btn {
      color: $darkTextColor !important;
      background-color: $lightTextColor !important;

      &:hover,
      &:focus {
        color: $lightTextColor !important;
        background-color: $darkTextColor !important;
      }
    }
  }
}

// ------------------CONTRAST------------------

.contrast {
  .jumbotrons-slide-v1 {
    background-color: $contrastBlackColor;

    &-item {
      border: 1px solid $contrastYellowColor;

      &::before {
        display: none;
      }
    }

    &-content {
      color: $contrastYellowColor;
    }

    &-buttons {
      .btn {
        color: $contrastYellowColor !important;
        background-color: $contrastBlackColor !important;
        background-image: none !important;
        border-color: $contrastYellowColor !important;

        &:hover,
        &:focus {
          color: $contrastBlackColor !important;
          background-color: $contrastYellowColor !important;
          border-color: $contrastYellowColor !important;
        }
      }
    }
  }
}
