.js-point-click {
  cursor: pointer;
}

.sr-only {
  position: absolute;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  white-space: nowrap;
  border-width: 0;
}

.aspect-auto {
  aspect-ratio: auto;
}

.aspect-square {
  aspect-ratio: 1 / 1;
}

.aspect-standard {
  aspect-ratio: 4 / 3;
}

.aspect-standard-vertical {
  aspect-ratio: 3 / 4;
}

.aspect-video {
  aspect-ratio: 16 / 9;
}

.aspect-video-vertical {
  aspect-ratio: 9 / 16;
}

.pre-wrap {
  white-space: pre-wrap;
}

.tabular-nums {
  font-variant: tabular-nums;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.mt-0 {
  margin-top: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.d {
  &-contents {
    display: contents !important;
  }

  &-inline {
    display: inline !important;
  }

  &-inline-block {
    display: inline-block !important;
  }

  &-block {
    display: block !important;
  }

  &-grid {
    display: grid !important;
  }

  &-table {
    display: table !important;
  }

  &-table-row {
    display: table-row !important;
  }

  &-table-cell {
    display: table-cell !important;
  }

  &-flex {
    display: flex !important;
  }

  &-inline-flex {
    display: inline-flex !important;
  }

  &-none {
    display: none !important;
  }
}

@media only screen and #{$sm-min-width} {
  .d-sm {
    &-contents {
      display: contents !important;
    }

    &-inline {
      display: inline !important;
    }

    &-inline-block {
      display: inline-block !important;
    }

    &-block {
      display: block !important;
    }

    &-grid {
      display: grid !important;
    }

    &-table {
      display: table !important;
    }

    &-table-row {
      display: table-row !important;
    }

    &-table-cell {
      display: table-cell !important;
    }

    &-flex {
      display: flex !important;
    }

    &-inline-flex {
      display: inline-flex !important;
    }

    &-none {
      display: none !important;
    }
  }
}

@media only screen and #{$md-min-width} {
  .d-md {
    &-contents {
      display: contents !important;
    }

    &-inline {
      display: inline !important;
    }

    &-inline-block {
      display: inline-block !important;
    }

    &-block {
      display: block !important;
    }

    &-grid {
      display: grid !important;
    }

    &-table {
      display: table !important;
    }

    &-table-row {
      display: table-row !important;
    }

    &-table-cell {
      display: table-cell !important;
    }

    &-flex {
      display: flex !important;
    }

    &-inline-flex {
      display: inline-flex !important;
    }

    &-none {
      display: none !important;
    }
  }
}

@media only screen and #{$lg-min-width} {
  .d-lg {
    &-contents {
      display: contents !important;
    }

    &-inline {
      display: inline !important;
    }

    &-inline-block {
      display: inline-block !important;
    }

    &-block {
      display: block !important;
    }

    &-grid {
      display: grid !important;
    }

    &-table {
      display: table !important;
    }

    &-table-row {
      display: table-row !important;
    }

    &-table-cell {
      display: table-cell !important;
    }

    &-flex {
      display: flex !important;
    }

    &-inline-flex {
      display: inline-flex !important;
    }

    &-none {
      display: none !important;
    }
  }
}

@media only screen and #{$xl-min-width} {
  .d-xl {
    &-contents {
      display: contents !important;
    }

    &-inline {
      display: inline !important;
    }

    &-inline-block {
      display: inline-block !important;
    }

    &-block {
      display: block !important;
    }

    &-grid {
      display: grid !important;
    }

    &-table {
      display: table !important;
    }

    &-table-row {
      display: table-row !important;
    }

    &-table-cell {
      display: table-cell !important;
    }

    &-flex {
      display: flex !important;
    }

    &-inline-flex {
      display: inline-flex !important;
    }

    &-none {
      display: none !important;
    }
  }
}

@media only screen and #{$xxl-min-width} {
  .d-xxl {
    &-contents {
      display: contents !important;
    }

    &-inline {
      display: inline !important;
    }

    &-inline-block {
      display: inline-block !important;
    }

    &-block {
      display: block !important;
    }

    &-grid {
      display: grid !important;
    }

    &-table {
      display: table !important;
    }

    &-table-row {
      display: table-row !important;
    }

    &-table-cell {
      display: table-cell !important;
    }

    &-flex {
      display: flex !important;
    }

    &-inline-flex {
      display: inline-flex !important;
    }

    &-none {
      display: none !important;
    }
  }
}

// ------------------CONTRAST------------------
.contrast {

}
