.attachments {
  overflow: hidden;

  &-list {
    & > *:not(:last-child) {
      margin-bottom: 5px;
    }
  }

  &-element {
    display: block;
    color: $darkTextColor;
    @include h300();
    @include transition(box-shadow, background-color, color, border-color);

    &:hover,
    &:focus {
      text-decoration: underline;
      color: $secondaryColor80;
    }

    span {
      @include transition(box-shadow, background-color, color, border-color);
    }

    &::before {
      content: "\f016";
      margin-right: 10px;
      vertical-align: text-top;
      font-family: $fontIconFamily;
    }

    &-jpg {
      &::before {
        content: '\f1c5';
      }
    }

    &-doc {
      &::before {
        content: "\f0f6";
      }

    }

    &-pdf {
      &::before {
        content: "\f1c1";
      }
    }

    &-txt {
      &::before {
        content: "\f0f6";
      }
    }

    &-rar {
      &::before {
        content: "\f1c6";
      }
    }

    &-ppt {
      &::before {
        content: "\f1c4";
      }
    }

    &-zip {
      &::before {
        content: "\f1c6";
      }
    }
  }
}

// ------------------CONTRAST------------------
.contrast {
  .attachments {
    &-element {
      color: $contrastYellowColor;

      &:hover,
      &:focus {
        color: $contrastWhiteColor;

        span {
          color: $contrastWhiteColor;
        }
      }

      span {
        color: $contrastYellowColor;
      }
    }
  }
}
