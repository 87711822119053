.article {
  overflow-x: auto;

  &-heading {
    &-image {
      width: 100%;
      border-radius: $borderRadius;
    }
  }

  &-content {
    overflow-x: auto;
    max-width: 800px;
    margin: 0 auto;

    & > *:not(:last-child) {
      margin-bottom: 32px;
    }
  }

  &-text {
    :where(h1, h2, h3, h4, h5, h6) {
      margin-bottom: 12px;
    }

    :where(p) {
      margin-bottom: 24px;
      color: $grayColor80;
    }

    :where(a) {
      color: $grayColor80;
      @include h200();

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }

    :where(img) {
      margin-bottom: 16px;
    }

    :where(table) {
      margin-bottom: 24px;
    }

    :where(ol, ul) {
      margin-bottom: 24px;
      padding-inline-start: 30px;

      :where(ol, ul) {
        margin-bottom: 0;
      }
    }

    :where(ol) {
      list-style: decimal;
    }

    :where(ul) {
      list-style: disc;

      :where(li) {
        &::marker {
          color: $grayColor100;
        }
      }
    }
  }

  &-photos {
    &-list {
      display: grid;
      gap: 24px;
      grid-template-columns: 1fr;

      @media #{$md-min-width} {
        grid-template-columns: repeat(2, 1fr);
      }

      @media #{$xl-min-width} {
        grid-template-columns: repeat(3, 1fr);
      }
    }

    &-link {
      &:hover,
      &:focus {
        .article-photos-image {
          transform: scale(0.95);
        }
      }
    }

    &-image {
      width: 100%;
      height: 100%;
      border-radius: $borderRadius;
      object-fit: cover;
      @include transition(transform);
    }
  }

  &-more {
    &-list {
      display: flex;
      gap: 8px;
      flex-direction: column;
    }

    &-grid {
      display: grid;
      gap: 16px;

      @media #{$md-min-width} {
        gap: 32px;
        grid-template-columns: repeat(2, 1fr);
      }

      @media #{$xl-min-width} {
        grid-template-columns: repeat(3, 1fr);
      }
    }

    &-item {

    }

    &-link {
      display: flex;
      @include h300();
      align-items: center;
      gap: 4px;
      color: $grayColor100;

      &:hover,
      &:focus,
      &.active {
        text-decoration: underline;
        color: $secondaryColor80;
      }
    }
  }

  &-meta {
    & > *:not(:last-child):not(.article-meta-separator) {
      margin-bottom: 12px;
    }

    &-separator {
      margin-top: 32px;
      margin-bottom: 32px;
    }

    &-badges {
      display: flex;
      align-items: center;
      gap: 12px;
      flex-wrap: wrap;
    }

    &-item {
      display: flex;
      align-items: center;
      padding: 0;
      color: $grayColor100;
      background-color: transparent;
      border: none;
      @include transition(box-shadow, background-color, color, border-color);

      &::before {
        display: inline-block;
        width: var(--size);
        height: var(--size);
        margin-right: 8px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        --size: 24px;
      }

      &:is(a, button) {
        &:hover,
        &:focus {
          text-decoration: underline;
          color: $secondaryColor100;
        }
      }

      &.action {
        @include h300();
      }

      &.text {
        margin-bottom: 16px;
        @include bodyLg();
      }
    }

    &-print {
      &::before {
        content: "";
        background-image: url("#{$assetsPath}/images/print.svg");
      }
    }

    &-pdf {
      &::before {
        content: "";
        background-image: url("#{$assetsPath}/images/download.svg");
      }
    }

    &-copy {
      &::before {
        content: "";
        background-image: url("#{$assetsPath}/images/copy.svg");
      }
    }

    &-facebook {
      &::before {
        content: "";
        background-image: url("#{$assetsPath}/images/facebook_primary.svg");
      }
    }

    &-twitter {
      &::before {
        content: "";
        background-image: url("#{$assetsPath}/images/twitter.svg");
      }
    }
  }
}

// ------------------CONTRAST------------------
.contrast {
  .article {
    &-text {
      :where(h1, h2, h3, h4, h5, h6) {
      }

      :where(p) {
        color: $contrastYellowColor;
      }

      :where(a) {
        color: $contrastWhiteColor;

        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }

      :where(table) {
        border-color: $contrastWhiteColor;
      }

      :where(ul) {
        :where(li) {
          &::marker {
            color: $contrastWhiteColor;
          }
        }
      }
    }

    &-photos {
      &-link {
        &:hover,
        &:focus {
          .article-photos-image {
            transform: scale(0.95);
          }
        }
      }
    }

    &-more {
      &-link {
        color: $contrastWhiteColor;

        &:hover,
        &:focus,
        &.active {
          text-decoration: underline;
        }
      }
    }

    &-meta {
      &-item {
        color: $contrastYellowColor;

        &:is(a, button) {
          &:hover,
          &:focus {
            color: $contrastWhiteColor;
          }
        }
      }

      &-print,
      &-copy,
      &-facebook,
      &-twitter,
      &-pdf {
        &::before {
          display: none;
        }
      }
    }
  }
}
