.events-calendar-v1 {
  width: 100%;
  padding: 16px;
  color: $grayColor100;
  background-color: $grayColor0;
  border: 1px solid transparent;
  border-radius: $borderRadius;

  @media #{$sm-min-width} {
    padding: 24px;
  }

  &.layout-aside-calendar {
    margin-right: auto;
    margin-left: auto;
  }

  .js-ajax-target {
    display: contents;
  }

  &-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    padding: 12px 0;
  }

  &-prev,
  &-next {
    position: relative;
    width: var(--size);
    height: var(--size);
    text-indent: -9999px;
    border-radius: 10px;
    --size: 32px;
    @include transition(box-shadow, background-color, color, border-color);

    &::before {
      content: "";
      position: absolute;
      inset: 0;
      z-index: 1;
      background-image: url("#{$assetsPath}/images/chevron_right.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: calc(var(--size) / 2);
    }

    &:hover,
    &:focus {
      background-color: $secondaryColor80;

      &::before {
        filter: brightness(100);
      }
    }
  }

  &-prev {
    &::before {
      rotate: 180deg;
    }
  }

  &-title {
    @include h200();
  }

  &-wrapper {

  }

  &-row {
    position: relative;
    display: grid;
    grid-template-columns: repeat(7, 1fr);

    &.headings {
      & > div {
        @include h200();
      }
    }

    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
      aspect-ratio: 1 / 1;
      @include bodyMd();

      &:nth-child(1) {
        &.has-events.open {
          .events-calendar-v1-event-wrapper {
            &::before {
              left: calc((100% / 7 / 2) * 1);
              transform: translateX(-50%);
            }
          }
        }
      }

      &:nth-child(2) {
        &.has-events.open {
          .events-calendar-v1-event-wrapper {
            &::before {
              left: calc((100% / 7 / 2) * 3);
              transform: translateX(-50%);
            }
          }
        }
      }

      &:nth-child(3) {
        &.has-events.open {
          .events-calendar-v1-event-wrapper {
            &::before {
              left: calc((100% / 7 / 2) * 5);
              transform: translateX(-50%);
            }
          }
        }
      }

      &:nth-child(4) {
        &.has-events.open {
          .events-calendar-v1-event-wrapper {
            &::before {
              left: calc((100% / 7 / 2) * 7);
              transform: translateX(-50%);
            }
          }
        }
      }

      &:nth-child(5) {
        &.has-events.open {
          .events-calendar-v1-event-wrapper {
            &::before {
              left: calc((100% / 7 / 2) * 9);
              transform: translateX(-50%);
            }
          }
        }
      }

      &:nth-child(6) {
        &.has-events.open {
          .events-calendar-v1-event-wrapper {
            &::before {
              left: calc((100% / 7 / 2) * 11);
              transform: translateX(-50%);
            }
          }
        }
      }

      &:nth-child(7) {
        &.has-events.open {
          .events-calendar-v1-event-wrapper {
            &::before {
              left: calc((100% / 7 / 2) * 13);
              transform: translateX(-50%);
            }
          }
        }
      }
    }
  }

  &-day {
    &.is-today {
      .events-calendar-v1-element {
        color: $whiteColor100;
        background-color: $secondaryColor80;
        background-image: $primaryColor80;
        border: none;

        &:is(a, button) {
          &:hover,
          &:focus {
            border: 1px solid $grayColor100;
          }
        }
      }
    }

    &.has-events {
      .events-calendar-v1-element {
        &::before {
          content: "";
          position: absolute;
          bottom: 10%;
          left: 50%;
          z-index: 1;
          width: 8px;
          height: 2px;
          user-select: none;
          transform: translateX(-50%);
          pointer-events: none;
          background-color: $grayColor100;
          border-radius: $roundedBorderRadius;
          --size: 5px;
        }
      }

      &.is-today {
        .events-calendar-v1-element {
          &::before {
            background-color: $whiteColor100;
          }
        }
      }
    }

    &.open {
      .events-calendar-v1-event-wrapper {
        display: flex;
      }
    }
  }

  &-element {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: var(--max-size);
    height: 100%;
    max-height: var(--max-size);
    margin-top: 1px;
    color: $grayColor100;
    border: 1px solid transparent;
    border-radius: 30px;
    --max-size: 60px;
    aspect-ratio: 1 / 1;
    @include transition(box-shadow, background-color, color, border-color);

    &:is(a, button) {
      &:hover,
      &:focus {
        text-decoration: none;
        border-color: $grayColor100;
      }
    }
  }

  &-event {
    &-wrapper {
      position: absolute;
      top: calc(100% + 15px);
      left: 50%;
      z-index: 2;
      display: none;
      gap: 4px;
      flex-direction: column;
      width: 100%;
      padding: 8px 16px;
      transform: translateX(-50%);
      color: $secondaryColor100;
      background-color: $whiteColor100;
      border: 1px solid $grayColor100;
      border-radius: 4px;
      @include h100();

      &::before {
        content: "";
        position: absolute;
        top: -10px;
        left: 50%;
        z-index: 3;
        width: 0;
        height: 0;
        transform: translateX(-50%);
        border-right: var(--size) solid transparent;
        border-bottom: var(--size) solid $grayColor100;
        border-left: var(--size) solid transparent;
        --size: 10px;
      }
    }

    &-link {
      padding: 4px 0;
    }
  }
}

// ------------------CONTRAST------------------
.contrast {
  .events-calendar-v1 {
    color: $contrastYellowColor;
    background-color: $contrastBlackColor;
    border: 1px solid $contrastYellowColor;

    &-prev,
    &-next {

      &::before {
        background-image: url("#{$assetsPath}/images/chevron_right.svg");
        filter: brightness(100);
      }

      &:hover,
      &:focus {
        background-color: $contrastWhiteColor;

        &::before {
          filter: brightness(0);
        }
      }
    }

    &-day {
      &.is-today {
        .events-calendar-v1-element {
          color: $contrastBlackColor;
          background-color: $contrastYellowColor;
          background-image: none;
        }
      }

      &.has-events {
        .events-calendar-v1-element {
          &::before {
            background-color: $contrastYellowColor;
          }

          &:hover,
          &:focus {
            &::before {
              background-color: $contrastBlackColor;
            }
          }
        }

        &.is-today {
          .events-calendar-v1-element {
            &::before {
              background-color: $contrastBlackColor;
            }
          }
        }
      }
    }

    &-element {
      color: $contrastYellowColor;

      &:is(a, button) {
        &:hover,
        &:focus {
          color: $contrastBlackColor !important;
          background-color: $contrastWhiteColor !important;
          border: none !important;
        }
      }
    }

    &-event {
      &-wrapper {
        color: $contrastYellowColor;
        background-color: $contrastBlackColor;
        border-color: $contrastYellowColor;

        &::before {
          border-bottom: var(--size) solid $contrastYellowColor;
        }
      }
    }
  }
}
