.module-gallery {
  &-list {
    display: grid;
    gap: 16px;

    @media #{$md-min-width} {
      gap: 32px;
      grid-template-columns: repeat(2, 1fr);
    }

    @media #{$xl-min-width} {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

// ------------------CONTRAST------------------
.contrast {

}
