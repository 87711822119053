.shortcuts-v1 {
  .heading {
    margin-bottom: 32px;
    text-align: center;
    @include h600();
  }

  &-list {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;

    @media #{$sm-min-width} {
      grid-template-columns: repeat(2, 1fr);
    }

    @media #{$lg-min-width} {
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 24px;
    }
  }

  &-element {
    display: flex;
    align-items: center;
    gap: 16px;
    height: 100%;
    padding: 16px 24px;
    word-break: break-word;
    color: $darkTextColor;
    background-color: $whiteColor100;
    border-radius: $borderRadius;
    box-shadow: $shadowSm;
    @include h400();
    @include transition(box-shadow, color);

    &:hover,
    &:focus {
      text-decoration: none;
      color: $secondaryColor80;
      box-shadow: $shadowMd;
    }

    @media #{$lg-min-width} {
      padding: 28px 32px;
    }
  }

  &-icon {
    display: inline-block;
    flex-shrink: 0;
    width: var(--size);
    height: var(--size);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    --size: 38px;
  }
}

// ------------------CONTRAST------------------

.contrast {
  .shortcuts-v1 {
    &-element {
      color: $contrastYellowColor;
      background-color: $contrastBlackColor;
      border: 1px solid $contrastYellowColor;

      &:hover,
      &:focus {
        text-decoration: none;
        color: $contrastBlackColor;
        background-color: $contrastYellowColor;
        border-color: $contrastYellowColor;
      }
    }

    &-icon {
      display: none;
    }
  }
}
