.module-events {
  &-search {
    &-form {

    }

    &-grid {
      display: grid;
      gap: 16px;
      grid-template-columns: repeat(1, 1fr);

      @media #{$md-min-width} {
        grid-template-columns: repeat(4, 1fr);

        & > :first-child,
        & > :nth-child(3) {
          grid-column: 1 / span 2;
        }

        & > :nth-child(2),
        & > :nth-child(4) {
          grid-column: 3 / span 2;
        }
      }

      @media #{$xl-min-width} {
        grid-template-columns: repeat(10, 1fr);

        & > :first-child {
          grid-column: 1 / span 3;
        }

        & > :nth-child(2) {
          grid-column: 4 / span 2;
        }

        & > :nth-child(3) {
          grid-column: 6 / span 2;
        }

        & > :nth-child(4) {
          grid-column: 8 / span 2;
        }

        & > :nth-child(5) {
          grid-column: 10 / span 1;
        }
      }
    }

    &-item {
      position: relative;
    }

    .btn {
      width: 100%;
      height: 100%;
    }
  }

  &-list {
    display: grid;
    gap: 16px;

    @media #{$md-min-width} {
      gap: 32px;
      grid-template-columns: repeat(2, 1fr);
    }

    @media #{$xl-min-width} {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &-filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
  }

  .with-arrow {
    display: flex;
    align-items: center;
    gap: 4px;

    &::before,
    &::after {
      display: inline-block;
      width: var(--size);
      height: var(--size);
      margin-bottom: 1px;
      background-image: url("#{$assetsPath}/images/arrow_right_alt.svg");
      background-repeat: no-repeat;
      background-position: center;
      --size: 20px;
      filter: brightness(100);
    }

    &.prev {
      &::before {
        content: "";
        transform: rotate(180deg);
      }
    }

    &.next {
      &::after {
        content: "";
      }
    }
  }
}

// ------------------CONTRAST------------------
.contrast {

}
