$transitionDuration2: $transitionDuration * 2;
$transitionDuration3: $transitionDuration * 3;
$transitionDuration4: $transitionDuration * 4;

.utility {
  position: fixed;
  bottom: 2.5rem;
  z-index: 1000;
  visibility: hidden;
  width: var(--size);
  height: var(--size);
  opacity: 0;
  --size: 50px;

  @media #{$sm-min-width} {
    bottom: 5rem;
  }

  &.utility-left {
    left: 1.5rem;

    @media #{$sm-min-width} {
      left: 3rem;
    }
  }

  &.utility-right {
    right: 1.5rem;

    @media #{$sm-min-width} {
      right: 3rem;
    }
  }

  &.show {
    visibility: visible;
    opacity: 1;
  }

  &-button {
    display: block;
    width: var(--size);
    height: var(--size);
    user-select: none;
    outline: none;
    color: $whiteColor100;
    background-image: $primaryColor80;
    border: 1px solid $secondaryColor80;
    border-radius: $roundedBorderRadius;
    --size: 50px;
    @include transition(box-shadow, background-color, background-image, color, border-color, opacity, visibility, transform);

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: var(--size);
      height: var(--size);
      transform: translate(-50%, -50%);
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      --size: 20px;
      filter: brightness(100);
      @include h200();
    }

    &:hover,
    &:focus {
      color: $whiteColor100;
      background-image: $primaryColor100;
    }
  }

  &-wcag {
    &.active {
      .utility-wcag-button {
        &:not(.wcag) {
          visibility: visible;
          opacity: 1;
        }
      }

      .wcag {
        &::before {
          background-image: url("#{$assetsPath}/images/close.svg");
        }
      }

      .resize-base {
        transform: translate(0, -125px);
        transition: background-color $transitionDuration $transitionTimingFunction, color $transitionDuration $transitionTimingFunction, border-color $transitionDuration $transitionTimingFunction, opacity $transitionDuration $transitionTimingFunction, visibility $transitionDuration $transitionTimingFunction, transform $transitionDuration $transitionTimingFunction;
      }

      .resize-medium {
        transform: translate(60px, -105px);
        transition: background-color $transitionDuration $transitionTimingFunction, color $transitionDuration $transitionTimingFunction, border-color $transitionDuration $transitionTimingFunction, opacity $transitionDuration2 $transitionTimingFunction, visibility $transitionDuration2 $transitionTimingFunction, transform $transitionDuration2 $transitionTimingFunction;
      }

      .resize-large {
        transform: translate(105px, -60px);
        transition: background-color $transitionDuration $transitionTimingFunction, color $transitionDuration $transitionTimingFunction, border-color $transitionDuration $transitionTimingFunction, opacity $transitionDuration3 $transitionTimingFunction, visibility $transitionDuration3 $transitionTimingFunction, transform $transitionDuration3 $transitionTimingFunction;
      }

      .contrast {
        transform: translate(125px, 0);
        transition: background-color $transitionDuration $transitionTimingFunction, color $transitionDuration $transitionTimingFunction, border-color $transitionDuration $transitionTimingFunction, opacity $transitionDuration4 $transitionTimingFunction, visibility $transitionDuration4 $transitionTimingFunction, transform $transitionDuration4 $transitionTimingFunction;
      }
    }

    &-button {
      position: absolute;

      &::before {
        --size: 22px;
      }

      &.wcag {
        z-index: 5;

        &::before {
          background-image: url("#{$assetsPath}/images/accessibility.svg");
        }
      }

      &:not(.wcag) {
        visibility: hidden;
        opacity: 0;
      }

      &.resize-base {
        z-index: 4;
        transition: background-color $transitionDuration $transitionTimingFunction, color $transitionDuration $transitionTimingFunction, border-color $transitionDuration $transitionTimingFunction, opacity $transitionDuration4 $transitionTimingFunction, visibility $transitionDuration4 $transitionTimingFunction, transform $transitionDuration4 $transitionTimingFunction;

        &::before {
          content: "A";
        }
      }

      &.resize-medium {
        z-index: 3;
        transition: background-color $transitionDuration $transitionTimingFunction, color $transitionDuration $transitionTimingFunction, border-color $transitionDuration $transitionTimingFunction, opacity $transitionDuration3 $transitionTimingFunction, visibility $transitionDuration3 $transitionTimingFunction, transform $transitionDuration3 $transitionTimingFunction;

        &::before {
          content: "A+";
        }
      }

      &.resize-large {
        z-index: 2;
        transition: background-color $transitionDuration $transitionTimingFunction, color $transitionDuration $transitionTimingFunction, border-color $transitionDuration $transitionTimingFunction, opacity $transitionDuration2 $transitionTimingFunction, visibility $transitionDuration2 $transitionTimingFunction, transform $transitionDuration2 $transitionTimingFunction;

        &::before {
          content: "A++";
        }
      }

      &.contrast {
        z-index: 1;
        transition: background-color $transitionDuration $transitionTimingFunction, color $transitionDuration $transitionTimingFunction, border-color $transitionDuration $transitionTimingFunction, opacity $transitionDuration $transitionTimingFunction, visibility $transitionDuration $transitionTimingFunction, transform $transitionDuration $transitionTimingFunction;

        &::before {
          background-image: url("#{$assetsPath}/images/contrast.svg");
        }
      }
    }
  }

  &-scroll-top {
    &::before {
      transform: translate(-50%, -50%) rotate(180deg);
      background-image: url("#{$assetsPath}/images/arrow_drop_down.svg");
    }
  }
}

// ------------------CONTRAST------------------
.contrast {
  .utility {
    &-button {
      color: $contrastWhiteColor;
      background-color: $contrastBlackColor;
      background-image: none;
      border-color: $contrastYellowColor;

      &::before {
        filter: brightness(100);
      }

      &:hover,
      &:focus {
        color: $contrastBlackColor;
        background-color: $contrastYellowColor;
        background-image: none;

        &::before {
          filter: brightness(0);
        }
      }
    }
  }
}
