@layer vendors {
  @import "bootstrap/scss/_mixins.scss";
  @import "bootstrap/scss/_functions.scss";
  @import "bootstrap/scss/_variables.scss";
  @import "bootstrap/scss/_grid.scss";
  @import "bootstrap/scss/_modal.scss";

  @import "bootstrap-datepicker/dist/css/bootstrap-datepicker3";

  @import "slick-carousel/slick/slick.scss";
  @import "slick-carousel/slick/slick-theme.scss";
}
