@function calculateRem($size) {
  $remSize: $size / 16px;
  @return #{$remSize}rem;
}

@mixin text($size, $lineHeight) {
  font-size: $size;
  font-size: calculateRem($size);
  line-height: calculateRem($lineHeight);
  font-weight: 400;
  letter-spacing: 0.2px;
}

@mixin heading($size, $lineHeight) {
  font-size: $size;
  font-size: calculateRem($size);
  line-height: calculateRem($lineHeight);
  font-weight: 700;
}

@mixin transition($property...) {
  transition-property: $property;
  transition-duration: $transitionDuration;
  transition-timing-function: $transitionTimingFunction;
}

@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200;0,6..12,300;0,6..12,400;0,6..12,600;0,6..12,700;1,6..12,300;1,6..12,400;1,6..12,600;1,6..12,700&display=swap");
@import "**/!(colors)/*.scss";
