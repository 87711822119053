.events-section-v1-search {
  &-form {

  }

  &-grid {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(1, 1fr);

    @media #{$md-min-width} {
      grid-template-columns: repeat(4, 1fr);

      & > :first-child,
      & > :nth-child(3) {
        grid-column: 1 / span 2;
      }

      & > :nth-child(2),
      & > :nth-child(4) {
        grid-column: 3 / span 2;
      }
    }

    @media #{$xl-min-width} {
      grid-template-columns: repeat(10, 1fr);

      & > :first-child {
        grid-column: 1 / span 3;
      }

      & > :nth-child(2) {
        grid-column: 4 / span 2;
      }

      & > :nth-child(3) {
        grid-column: 6 / span 2;
      }

      & > :nth-child(4) {
        grid-column: 8 / span 2;
      }

      & > :nth-child(5) {
        grid-column: 10 / span 1;
      }
    }
  }

  &-item {
    position: relative;
  }

  .btn {
    width: 100%;
    height: 100%;
  }
}

// ------------------CONTRAST------------------

.contrast {
  .events-section-v1-search {
  }
}
