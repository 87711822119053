.module-applications {
  &-search {
    &-form {

    }

    &-grid {
      display: flex;
      gap: 16px;
      flex-direction: column;

      @media #{$lg-min-width} {
        flex-direction: row;
      }
    }

    &-item {
      position: relative;

      &:first-child {
        flex-grow: 1;
      }
    }

    .btn {
      width: 100%;
      height: 100%;
    }
  }

  &-list {
    display: grid;
    gap: 12px;

    &.extra-space {
      gap: 48px;
    }

    &.extra-margin {
      margin-left: 12px;
    }
  }

  &-item {

  }

  &-letter {
    @include h500();
    margin-bottom: 8px;
    margin-left: 12px;
    text-transform: capitalize;
  }

  &-separator {
    width: 100%;
    height: 4px;
    margin-bottom: 16px;
    background-image: $primaryColor100;
    background-repeat: no-repeat;
    background-position: center;
  }

  &-element {
    display: block;
    @include bodyLg();
    color: $darkTextColor;

    &::first-letter {
      @include h500();
      text-transform: capitalize;
    }
  }

  .article {
    &-rwa {
      @include bodyLg();
      display: flex;
      align-items: center;
      gap: 12px;
      margin-bottom: 24px;
      line-height: 1.5;
      color: $grayColor80;

      span {
        @include subtitle();
        line-height: 1.5;
        color: $darkTextColor;
      }
    }

    &-title {
      margin-bottom: 16px;
    }

    &-meta {
      margin-bottom: 32px;
    }
  }
}

// ------------------CONTRAST------------------

.contrast {
  .module-applications {
    &-separator {
      background-color: $contrastYellowColor;
      background-image: none;
    }

    &-element {
      color: $contrastYellowColor;
    }

    .article {
      &-rwa {
        color: $contrastYellowColor;

        span {
          color: $contrastYellowColor;
        }
      }
    }
  }
}
