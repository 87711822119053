.gallery-images-v1 {
  &-more {
  }

  &-list {
    display: grid;
    gap: 24px;
    grid-template-columns: 1fr;

    @media #{$md-min-width} {
      grid-template-columns: repeat(2, 1fr);
    }

    @media #{$xl-min-width} {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &-link {
    &:hover,
    &:focus {
      .gallery-images-v1-image {
        transform: scale(0.95);
      }
    }
  }

  &-image {
    width: 100%;
    height: 100%;
    border-radius: $borderRadius;
    object-fit: cover;
    @include transition(transform);
  }

  .js-btn-load {
    margin-top: 32px;
  }
}

// ------------------CONTRAST------------------
.contrast {

}
