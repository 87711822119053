.module-news {
  &-search {
    &-form {

    }

    &-grid {
      display: flex;
      gap: 16px;
      flex-direction: column;

      @media #{$lg-min-width} {
        flex-direction: row;
      }
    }

    &-item {
      position: relative;

      &:first-child {
        flex-grow: 1;
      }
    }

    .btn {
      width: 100%;
      height: 100%;
    }
  }

  &-list {
    display: grid;
    gap: 16px;

    @media #{$md-min-width} {
      gap: 32px;
      grid-template-columns: repeat(2, 1fr);
    }

    @media #{$xl-min-width} {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

// ------------------CONTRAST------------------
.contrast {

}
