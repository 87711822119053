.module-contact {
  &-employees {
    margin-bottom: 16px;

    @media #{$md-min-width} {
      margin-bottom: 32px;
    }

    @media #{$xl-min-width} {
      margin-bottom: 64px;
    }

    &-list {
      & > *:not(:last-child) {
        margin-bottom: 32px;
      }
    }
  }

  &-grid {
    display: grid;
    gap: 16px;
    margin-bottom: 16px;

    @media #{$md-min-width} {
      gap: 32px;
      margin-bottom: 32px;
    }

    @media #{$lg-min-width} {
      grid-template-columns: repeat(2, 1fr);
    }

    @media #{$xl-min-width} {
      gap: 64px;
      margin-bottom: 64px;
    }
  }

  &-subheading {
    @include h400();
    margin-bottom: 16px;
    color: $grayColor100;
  }

  p {
    @include bodyLg();
    margin-bottom: 4px;
    color: $grayColor80;
  }
}

// ------------------CONTRAST------------------
.contrast {
  .module-contact {
    &-subheading {
      color: $contrastYellowColor;
    }

    p {
      color: $contrastYellowColor;
    }
  }
}
