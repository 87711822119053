// Paths
$assetsPath: "/layouts/jst_05/assets";

// Fonts
$fontFamily: "Nunito Sans", system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
$fontIconFamily: "FontAwesome";

@mixin h900() {
  @include heading(56px, 56px);

  @media #{$sm-min-width} {
    @include heading(64px, 56px);
  }
}

@mixin h800() {
  @include heading(56px, 56px);
}

@mixin h700() {
  @include heading(40px, 48px);
}

@mixin h600() {
  @include heading(32px, 42px);
}

@mixin h500() {
  @include heading(24px, 32px);
}

@mixin h400() {
  @include heading(20px, 28px);
}

@mixin h300() {
  @include heading(16px, 24px);
}

@mixin h200() {
  @include heading(14px, 20px);
}

@mixin h100() {
  @include heading(12px, 16px);
}

@mixin subtitle() {
  @include heading(18px, 27px);
}

@mixin bodyXl() {
  @include text(24px, 40px);
}

@mixin bodyLg() {
  @include text(18px, 32px);
}

@mixin bodyMd() {
  @include text(14px, 20px);
}

@mixin bodySm() {
  @include text(12px, 16px);
}

// Border Radius
$borderRadius: 4px;
$borderRadiusLg: 24px;
$roundedBorderRadius: 400px;

// Shadows
$shadowSm: 0px 2px 4px 0px rgba(58, 57, 76, 0.03), 0px 2px 8px 0px rgba(58, 57, 76, 0.08);
$shadowMd: 0px 4px 6px 0px rgba(58, 57, 76, 0.06), 0px 6px 20px -2px rgba(58, 57, 76, 0.14);
$shadowLg: 0px 6px 8px 0px rgba(58, 57, 76, 0.06), 0px 9px 34px -4px rgba(58, 57, 76, 0.14);

// Outline
$outlineStyle: solid;
$outlineWidth: 2px;
$outlineOffset: 3px;

// Transition
$transitionDuration: 0.2s;
$transitionTimingFunction: ease;

// Gutters
$gutterTile: 4px;
$gutterArticle: 10px;

// Breakpoints
$smBreakpoint: 640px;
$mdBreakpoint: 768px;
$lgBreakpoint: 1024px;
$xlBreakpoint: 1280px;
$xxlBreakpoint: 1536px;

$sm-min-width: "(min-width: #{$smBreakpoint})";
$sm-max-width: "(max-width: #{$smBreakpoint - 1})";

$md-min-width: "(min-width: #{$mdBreakpoint})";
$md-max-width: "(max-width: #{$mdBreakpoint - 1})";

$lg-min-width: "(min-width: #{$lgBreakpoint})";
$lg-max-width: "(max-width: #{$lgBreakpoint - 1})";

$xl-min-width: "(min-width: #{$xlBreakpoint})";
$xl-max-width: "(max-width: #{$xlBreakpoint - 1})";

$xxl-min-width: "(min-width: #{$xxlBreakpoint})";
$xxl-max-width: "(max-width: #{$xxlBreakpoint - 1})";
