$height: calculateRem(300px);
$lgMinHeight: calculateRem(400px);

.jumbotrons-slider-v1 {
  overflow: hidden;
  margin-bottom: 32px;

  .slick {
    &-controls {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;
      flex-direction: column;
      min-height: 40px;
      padding: 0 16px;

      @media #{$lg-min-width} {
        gap: 0;
        flex-direction: row;
        padding: 0 160px;
      }

      &-right {
        display: flex;
        align-items: center;
        gap: 12px;

        @media #{$lg-min-width} {
          position: absolute;
          top: 50%;
          right: 0;
          z-index: 1;
          translate: 0 -50%;
        }

        & > * {
          flex-shrink: 0;
        }
      }
    }

    &-slide {
      overflow: hidden;
    }

    &-pause-play {
      margin-left: 12px;
    }
  }

  &-list {
    position: relative;
    min-height: $height;
    margin-bottom: 0;
    padding-left: 0;

    @media #{$lg-min-width} {
      min-height: $lgMinHeight;
    }
  }

  &-item {
    position: relative;
    display: none;
    flex-direction: column;
    height: 100%;
    -webkit-backface-visibility: hidden;

    &:first-child {
      display: flex !important;
      -webkit-backface-visibility: visible;
    }

    @media #{$lg-max-width} {
      max-width: 100%;
      margin: 0;
      padding: 0;
    }

    @media #{$lg-min-width} {
      gap: 60px;
      flex-direction: row;
      padding-top: 50px;
      padding-bottom: 50px;
      -webkit-backface-visibility: visible;
    }

    &.fullWidth {
      .jumbotrons-slider-v1-content {
        @media #{$lg-min-width} {
          position: absolute;
          top: 68px;
          left: 50%;
          padding: 20px 32px;
          translate: -50% 0;
        }
      }
    }

    &:is(.halfWidthLeft, .halfWidthRight) {
      .jumbotrons-slider-v1-image-wrapper {
        @media #{$lg-min-width} {
          flex-basis: 60%;
        }
      }

      .jumbotrons-slider-v1-content {
        @media #{$lg-min-width} {
          flex-basis: 40%;
        }
      }
    }

    &:is(.halfWidthLeft) {
      @media #{$lg-min-width} {
        flex-direction: row-reverse;

        .jumbotrons-slider-v1-image-wrapper {
          &::before {
            translate: -60px 40px;
          }
        }
      }
    }
  }

  &-image {
    width: 100%;
    height: 100%;
    min-height: $height;
    object-fit: cover;
    object-position: center;

    @media #{$lg-min-width} {
      min-height: $lgMinHeight;
      border-radius: $borderRadius;
    }

    &-wrapper {
      position: relative;
      aspect-ratio: 1920 / 400;

      @media #{$lg-min-width} {
        border-radius: $borderRadius;
      }

      &::before {
        @media #{$lg-min-width} {
          content: '';
          position: absolute;
          inset: 0;
          z-index: -1;
          background-image: $primaryColor100;
          border-radius: $borderRadius;
          translate: 60px 40px;
        }
      }
    }
  }

  &-content {
    padding-top: 24px;
    padding-bottom: 24px;
    color: $darkTextColor;
    background-color: $whiteColor100;
    border-radius: $borderRadius;

    @media #{$lg-min-width} {
      position: relative;
      top: 40px;
      padding: 0;
    }

    .container {
      display: grid;
      gap: 12px;
    }

    &.halfWidthLeft {
      @media #{$lg-min-width} {
        width: 50%;
      }
    }

    &.halfWidthRight {
      @media #{$lg-min-width} {
        width: 50%;
        margin-left: auto;
      }
    }

    &.textAlignLeft {
      text-align: left;

      .jumbotrons-slider-v1-buttons {
        text-align: left;
      }
    }

    &.textAlignCenter {
      text-align: center;

      .jumbotrons-slider-v1-buttons {
        text-align: center;
      }
    }

    &.textAlignRight {
      text-align: right;

      .jumbotrons-slider-v1-buttons {
        text-align: right;
      }
    }
  }

  &-heading {
    margin-bottom: 4px;
    @include h500();

    @media #{$lg-min-width} {
      @include h600();
    }
  }

  &-subheading {
    @include h400();

    @media #{$lg-min-width} {
      @include h500();
    }
  }

  &-text {
    color: $grayColor60;
    @include h200();

    @media #{$lg-min-width} {
      @include h300();
    }
  }

  &-buttons {
    margin-top: 12px;

    .btn {
      color: $lightTextColor !important;
      background-image: $primaryColor80 !important;

      &:hover:not(:disabled, .disabled),
      &:focus:not(:disabled, .disabled) {
        color: $lightTextColor !important;
        background-image: $primaryColor100 !important;
      }
    }
  }
}

// ------------------CONTRAST------------------

.contrast {
  .jumbotrons-slider-v1 {
    background-color: $contrastBlackColor;

    &-image {
      &-wrapper {
        &::before {
          @media #{$lg-min-width} {
            display: none;
          }
        }
      }
    }

    &-content {
      color: $contrastYellowColor;
      background-color: $contrastBlackColor;
      border: 1px solid $contrastYellowColor;
    }

    &-text {
      color: $contrastYellowColor;
    }

    &-buttons {
      .btn {
        color: $contrastYellowColor !important;
        background-color: $contrastBlackColor !important;
        background-image: none !important;
        border-color: $contrastYellowColor !important;

        &:hover,
        &:focus {
          color: $contrastBlackColor !important;
          background-color: $contrastYellowColor !important;
          border-color: $contrastYellowColor !important;
        }
      }
    }
  }
}
