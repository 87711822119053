.video-list-v1 {
  position: relative;

  &-wrapper {
    padding: 48px 16px;
    background-color: $whiteColor100;
    border-radius: $borderRadius;

    @media #{$sm-min-width} {
      padding: 48px;
    }
  }

  &-list {
    display: grid;
    gap: 48px;

    @media #{$sm-min-width} {
      justify-content: center;
      grid-template-columns: 1fr 1fr;
    }

    @media #{$lg-min-width} {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  &-item {
  }
}

// ------------------CONTRAST------------------
.contrast {
  .video-list-v1 {
    &-wrapper {
      background-color: $contrastBlackColor;
      border: 1px solid $contrastYellowColor;
    }
  }
}
