.form {
  &-label {
    color: $darkTextColor;
    @include bodyMd();
  }

  &-control {
    display: block;
    width: 100%;
    padding: 12px 16px;
    appearance: none;
    color: $darkTextColor;
    background-color: $whiteColor100;
    border: 1px solid $grayColor100;
    border-radius: $roundedBorderRadius;
    @include bodyMd();
    @include transition(box-shadow, background-color, color, border-color);

    &:hover,
    &:focus {
      border-color: $secondaryColor80;
    }

    &::placeholder {
      opacity: 1;
      color: $grayColor80;
    }

    &:disabled {
      cursor: not-allowed;
      pointer-events: none;
      color: $grayColor60;
      background-color: $whiteColor100;
      border-color: $grayColor40;

      &::placeholder {
        color: $grayColor60;
      }
    }

    &.error {
      color: $errorColor50;
      background-color: $whiteColor100;
      border-color: $errorColor50;

      &::placeholder {
        color: $errorColor50;
      }
    }
  }

  &-datepicker {
    padding-right: 44px;

    &-wrapper {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        right: 16px;
        width: var(--size);
        height: var(--size);
        pointer-events: none;
        background-color: $whiteColor100;
        background-image: url("#{$assetsPath}/images/calendar.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        --size: 20px;
        translate: 0 -50%;
      }
    }
  }

  .js-datepicker-container {
    position: relative;
  }

  &-select {
    position: relative;

    @media #{$sm-min-width} {
      max-width: 250px;
    }

    @media #{$md-min-width} {
      max-width: 300px;
    }

    @media #{$lg-min-width} {
      max-width: 350px;
    }

    .form-control {
      padding-right: 44px;
    }

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: 16px;
      width: var(--size);
      height: var(--size);
      pointer-events: none;
      background-image: url("#{$assetsPath}/images/expand.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      --size: 20px;
      translate: 0 -50%;
    }
  }

  &-radio {
    position: relative;
    display: inline-flex;
    align-items: center;

    &-input {
      position: absolute;
      top: 50%;
      left: 0;
      display: inline-flex;
      flex-grow: 0;
      flex-shrink: 0;
      width: var(--size);
      height: var(--size);
      appearance: none;
      transform: translateY(-50%);
      color: $darkTextColor;
      background-color: $whiteColor100;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 1rem;
      border: 1px solid $grayColor40;
      border-radius: $roundedBorderRadius;
      --size: 1.25rem;
      @include transition(box-shadow, background-color, color, border-color);

      &:hover,
      &:focus {
        background-color: $secondaryColor10;
        border-color: $secondaryColor80;

        ~ .form-radio-label {
          cursor: pointer;
          text-decoration: underline;
        }
      }

      &:checked {
        color: $whiteColor100;
        background-color: $secondaryColor80;
        background-image: url("#{$assetsPath}/images/check.svg");
        border-color: $secondaryColor80;
      }

      &:disabled {
        cursor: not-allowed;
        pointer-events: none;
        color: $grayColor60;
        background-color: $grayColor5;
        border-color: $grayColor40;
      }
    }

    &-label {
      display: block;
      width: 100%;
      padding: 12px 0 12px 32px;
      color: $darkTextColor;
      @include bodyMd();
    }
  }

  &-search {
    position: relative;

    .form-control {
      padding: 12px 48px 12px 16px;
    }

    &-submit {
      position: absolute;
      top: 50%;
      right: 2px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: calc(100% - 4px);
      color: $darkTextColor;
      background-color: $whiteColor100;
      border-radius: $roundedBorderRadius;
      aspect-ratio: 1 / 1;
      translate: 0 -50%;
      @include transition(box-shadow, background-color, color, border-color);

      &:hover,
      &:focus {
        color: $whiteColor100;
        background-color: $grayColor100;
      }

      &:disabled,
      &.disabled {
        cursor: not-allowed;
        background-color: $grayColor5 !important;
      }

      .fa {
        @include bodyLg();
      }
    }
  }
}

// ------------------CONTRAST------------------
.contrast {
  .form {
    &-label {
      color: $contrastYellowColor;
    }

    &-control {
      color: $contrastYellowColor;
      background-color: $contrastBlackColor;
      border-color: $contrastYellowColor;

      &:hover,
      &:focus {
        border-color: $contrastWhiteColor;
      }

      &::placeholder {
        opacity: 1;
        color: $contrastYellowColor;
      }

      &:disabled {
        color: $contrastYellowColor;
        background-color: $contrastBlackColor;
        border-color: $contrastYellowColor;

        &::placeholder {
          opacity: 1;
          color: $contrastYellowColor;
        }
      }

      &.error {
        color: $errorColor100;
        background-color: $contrastBlackColor;
        border-color: $errorColor100;

        &::placeholder {
          opacity: 1;
          color: $errorColor100;
        }
      }
    }

    &-datepicker {
      &-wrapper {
        &::after {
          background-color: transparent;
          filter: brightness(100);
        }
      }
    }

    &-select {
      &::after {
        filter: brightness(100);
      }
    }

    &-radio {
      &-input {
        color: $contrastYellowColor;
        background-color: $contrastBlackColor;
        border-color: $contrastYellowColor;

        &:hover,
        &:focus {
          background-color: $contrastWhiteColor;
          border-color: $contrastWhiteColor;
        }

        &:checked {
          color: $contrastYellowColor;
          background-color: $contrastBlackColor;
          border-color: $contrastYellowColor;
        }

        &:disabled {
          color: $grayColor60;
          background-color: $grayColor5;
          border-color: $grayColor5;
        }
      }

      &-label {
        color: $contrastYellowColor;
      }
    }

    &-search {
      &-submit {
        color: $contrastYellowColor;
        background-color: $contrastBlackColor;
        border-color: $contrastBlackColor;

        &:hover,
        &:focus {
          color: $contrastBlackColor;
          background-color: $contrastYellowColor;
          border-color: $contrastYellowColor;
        }

        &:disabled,
        &.disabled {
          border-color: $grayColor5 !important;
        }
      }
    }
  }
}
