.text-reader {
  display: none;
  margin-bottom: 12px;

  &[data-text-reader-ready] {
    display: block;
  }

  &-button {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 38px;
    padding: 5px;
    aspect-ratio: 1;
  }

  [data-text-reader-play] {
  }

  [data-text-reader-pause] {
    display: none;
  }

  [data-text-reader-resume] {
    display: none;
  }

  [data-text-reader-stop] {
    display: none;
  }
}
