.module-polls {
  &-list {
    & > *:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &-answer {
    @include bodyMd();
    margin-bottom: 8px;
    color: $grayColor100;
  }

  &-item {
    .progress {
      flex-grow: 1;
      background-color: $secondaryColor10;

      &-wrapper {
        display: flex;
        align-items: center;
        width: 100%;
      }

      &.success {
        .bar {
          background-color: $secondaryColor80;
        }
      }

      &.error {
        .bar {
          background-color: $errorColor100;
        }
      }

      .bar {
        min-width: 80px;
        padding: 10px 14px;
        white-space: nowrap;
        color: $whiteColor100;
        background-color: $secondaryColor80;
        border-radius: $borderRadius 0 0 $borderRadius;
      }
    }

    .btn-vote {
      height: 100%;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

// ------------------CONTRAST------------------
.contrast {
  .module-polls {
    &-answer {
      color: $contrastYellowColor;
    }

    &-item {
      .progress {
        background-color: $contrastBlackColor;
        border: 1px solid $contrastYellowColor;

        &.success {
          .bar {
            background-color: $contrastWhiteColor;
          }
        }

        &.error {
          .bar {
            background-color: $contrastYellowColor;
          }
        }

        .bar {
          color: $contrastBlackColor;
          background-color: $contrastYellowColor;
        }
      }
    }
  }
}
