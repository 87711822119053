.module {
  & > *:not(:last-child) {
    margin-bottom: 16px;

    @media #{$sm-min-width} {
      margin-bottom: 32px;
    }
  }

  &-wrapper {
    padding: 48px 16px;
    background-color: $whiteColor100;
    border-radius: $borderRadius;

    @media #{$sm-min-width} {
      padding: 48px;
    }

    & > *:not(:last-child) {
      margin-bottom: 32px;
    }
  }
}

// ------------------CONTRAST------------------
.contrast {
  .module {
    &-wrapper {
      background-color: $contrastBlackColor;
      border: 1px solid $contrastYellowColor;
    }
  }
}
