.sub-page-navigation {
  .breadcrumbs {
    &-list {
      display: flex;
      gap: 8px;
      flex-wrap: wrap;
      width: 100%;
    }

    &-item {
      display: inline-flex;
      align-items: center;

      &:not(:last-child) {
        &::after {
          content: "";
          display: inline-block;
          width: 1rem;
          height: 1rem;
          margin-left: 8px;
          background-image: url("#{$assetsPath}/images/arrow_right.svg");
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
        }
      }
    }

    &-element {
      color: $grayColor80;
      @include transition(box-shadow, background-color, color, border-color);

      &:is(a, button) {
        color: $grayColor100;

        &:hover,
        &:focus {
          text-decoration: underline;
          color: $grayColor100;
        }
      }
    }
  }
}

// ------------------CONTRAST------------------
.contrast {
  .sub-page-navigation {
    .breadcrumbs {
      &-item {
        &:not(:last-child) {
          &::after {
            filter: brightness(100);
          }
        }
      }

      &-element {
        color: $contrastYellowColor;

        &:is(a, button) {
          color: inherit;

          &:hover,
          &:focus {
            color: $contrastWhiteColor;
          }
        }
      }
    }
  }
}
