.events-section-v1 {
  position: relative;

  &-wrapper {
    padding: 48px 16px;
    background-color: $whiteColor100;
    border-radius: $borderRadius;

    @media #{$sm-min-width} {
      padding: 48px;
    }
  }

  &-search {
    margin-bottom: 32px;
  }

  &-grid {
    display: grid;
    align-items: start;
    gap: 32px;
    grid-template-columns: 1fr;

    @media #{$sm-min-width} {
      grid-template-columns: 300px 1fr;
    }

    @media #{$lg-min-width} {
      grid-template-columns: 350px 1fr;
    }

    @media #{$xl-min-width} {
      grid-template-columns: 400px 1fr;
    }
  }
}

// ------------------CONTRAST------------------
.contrast {
  .events-section-v1 {
    &-wrapper {
      background-color: $contrastBlackColor;
      border: 1px solid $contrastYellowColor;
    }
  }
}
