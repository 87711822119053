.events-upcoming-v1 {
  &.empty {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    flex-direction: column;
    height: 100%;

    p {
      text-align: center;
      color: $grayColor60;
      @include h400();
    }
  }

  &-heading {
    margin-bottom: 16px;
    @include h300();
  }

  &-list {
    display: flex;
    gap: 20px;
    flex-direction: column;
  }

  &-item {
  }

  &-link {
    display: block;
    padding: 16px;
    background-color: $whiteColor100;
    border-radius: $borderRadius;
    box-shadow: $shadowSm;
    @include transition(box-shadow, background-color, color, border-color);

    &:hover,
    &:focus {
      text-decoration: none;
      box-shadow: $shadowMd;
    }

    & > *:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &-date {
    color: $grayColor100;
  }

  &-title {
    display: block;
    color: $grayColor100;
    @include h300();
  }

  &-text {
    display: block;
    margin-top: 8px;
    color: $grayColor80;
    @include bodyMd();
  }
}

// ------------------CONTRAST------------------
.contrast {
  .events-upcoming-v1 {
    &.empty {
      img {
        filter: brightness(100);
      }

      p {
        color: $contrastYellowColor;
      }
    }

    &-link {
      color: $contrastYellowColor;
      background-color: $contrastBlackColor;
      border: 1px solid $contrastYellowColor;
      box-shadow: none;

      &:hover,
      &:focus {
        color: $contrastBlackColor;
        background-color: $contrastYellowColor;
        border-color: $contrastYellowColor;
        box-shadow: none;
      }
    }

    &-date {
      color: inherit;
    }

    &-title {
      color: inherit;
    }

    &-text {
      color: inherit;
    }
  }
}
