.sitemap {
  &-list {
    & & {
      margin-top: 0.5rem;
    }
  }

  &-heading {
    margin-top: 1.5rem;
  }

  &-text {
    font-size: inherit;
    line-height: inherit;
    color: $darkTextColor;
  }

  &-link {
    text-decoration: none;
    font-size: inherit;
    line-height: inherit;
    color: $secondaryColor100;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}

// ------------------CONTRAST------------------
.contrast {
  .sitemap {
    &-text {
      color: $contrastYellowColor;
    }

    &-link {
      text-decoration: none;
      color: $contrastWhiteColor;

      &:hover,
      &:focus {
        text-decoration: underline;
        color: $contrastYellowColor;
      }
    }
  }
}
