.footer {
  color: $lightTextColor;

  :where(a) {
    word-break: break-word;
    color: $lightTextColor;

    &:focus,
    &:hover {
      text-decoration: underline;
      color: $lightTextColor;
    }
  }

  &-wrapper {
    padding: 60px 0 80px;
    background-image: $primaryColor80;

    & > .container {
      display: flex;
      gap: 42px;
      flex-direction: column;
    }
  }

  &-menu {
    &-list {
      display: flex;
      gap: 32px;
      flex-direction: column;

      @media #{$sm-min-width} {
        align-items: center;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
      }
    }

    &-item {
      display: flex;
    }

    &-link {
      width: 100%;
      padding: 8px 16px;
      border: 2px solid transparent;
      border-radius: $borderRadius;
      @include h300();
      @include transition(box-shadow, background-color, color, border-color);

      &:hover,
      &:focus {
        text-decoration: none;
        color: $whiteColor100;
        background-color: $secondaryColor80;
        border-color: $whiteColor100;
      }

      &.active {
        color: $secondaryColor80;
        background-color: $whiteColor100;
      }
    }
  }

  &-grid {
    display: grid;
    gap: 40px;

    @media #{$md-min-width} {
      grid-template-columns: repeat(2, 1fr);
    }

    @media #{$xl-min-width} {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &-column {
    display: flex;
    gap: 24px;
    flex-direction: column;

    & > div {
      & > * + *:not(div) {
        margin-top: 8px;
      }
    }
  }

  &-motto {
    text-wrap: balance;
    @include h200();
  }

  &-stats {
    display: flex;
    align-items: stretch;
    gap: 16px;
  }

  &-stat {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 12px;
    color: $darkTextColor;
    background-color: $whiteColor100;
    border-radius: $borderRadius;

    &-number {
      @include h200();
    }

    &-text {
      @include bodySm();
    }
  }

  &-heading {
    text-wrap: balance;
    @include h400();
  }

  &-subheading {
    @include bodyMd();
  }

  &-links {
    @include h300();

    a {
      display: block;
    }
  }

  &-copyrights {
    padding: 24px 0;
    background-color: $grayColor100;

    .container {
      display: flex;
      gap: 16px;
      flex-direction: column;

      @media #{$sm-min-width} {
        flex-direction: row;
      }

      & > * {
        flex-basis: 0;
        flex-grow: 1;
      }
    }
  }

  &-copy {
    text-align: center;
    word-break: break-word;
    @include bodySm();
    color: $whiteColor100;

    @media #{$sm-min-width} {
      text-align: left;
    }
  }

  &-sitemap {
    text-align: center;
    word-break: break-word;
    @include bodySm();
    color: $whiteColor100;
  }

  &-realization {
    text-align: center;
    white-space: nowrap;
    @include bodySm();
    word-break: break-word;
    color: $whiteColor100;

    @media #{$sm-min-width} {
      text-align: right;
    }
  }
}

// ------------------CONTRAST------------------
.contrast {
  .footer {
    color: $contrastYellowColor;
    border-top: 1px solid $contrastYellowColor;

    :where(a) {
      color: $contrastYellowColor;

      &:focus,
      &:hover {
        text-decoration: underline;
        color: $contrastWhiteColor;
      }
    }

    &-wrapper {
      background-color: $contrastBlackColor;
      background-image: none;
    }

    &-menu {
      &-link {
        color: $contrastWhiteColor;
        background-color: $contrastBlackColor;
        border: 1px solid $contrastYellowColor;

        &:hover,
        &:focus {
          text-decoration: none;
          color: $contrastBlackColor;
          background-color: $contrastYellowColor;
          border-color: $contrastYellowColor;
        }

        &.active {
          color: $contrastBlackColor;
          background-color: $contrastWhiteColor;
          border-color: $contrastWhiteColor;
        }
      }
    }

    &-stat {
      color: $contrastYellowColor;
      background-color: $contrastBlackColor;
      border: 1px solid $contrastYellowColor;
    }

    &-copyrights {
      background-color: $contrastBlackColor;
      border-top: 1px solid $contrastYellowColor;
    }

    &-copy {
      color: $contrastWhiteColor;
    }

    &-sitemap {
      color: $contrastWhiteColor;
    }

    &-realization {
      color: $contrastWhiteColor;
    }
  }
}
