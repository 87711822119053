.header {
  &-options {
    padding: 16px 0;
    color: $whiteColor100;
    background-color: $grayColor100;

    & > .container {
      display: flex;
      align-items: center;
      justify-content: end;
      gap: 16px;

      @media #{$md-min-width} {
        justify-content: space-between;
      }
    }

    &-left {
      display: none;
      gap: 16px;

      @media #{$md-min-width} {
        display: flex;
        align-items: center;
      }
    }

    &-right {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    &-socials {
      display: flex;
      align-items: center;
      gap: 16px;
      margin-right: 24px;
      padding-right: 24px;
      border-right: 1px solid $whiteColor100;
    }

    &-social {
      width: var(--size);
      height: var(--size);
      font-size: 0;
      background-repeat: no-repeat;
      background-position: center;
      border-radius: $borderRadius;
      --size: 32px;
      @include transition(box-shadow, background-color, color, border-color);

      &:hover,
      &:focus {
        background-color: $secondaryColor100;
      }
    }

    &-facebook {
      background-image: url("#{$assetsPath}/images/facebook.svg");
      background-size: 22px;
    }

    &-contacts {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    &-contact {
      display: inline-flex;
      align-items: center;
      gap: 12px;
      color: $whiteColor100;
      @include h300();

      &::before {
        content: "";
        display: block;
        width: var(--size);
        height: var(--size);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        --size: 24px;
      }
    }

    &-mail {
      &::before {
        background-image: url("#{$assetsPath}/images/mail.svg");
      }
    }

    &-tel {
      &::before {
        background-image: url("#{$assetsPath}/images/tel.svg");
      }
    }

    &-bip {
      height: 28px;
      font-size: 0;
      background-image: url("#{$assetsPath}/images/bip.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 54px 24px;
      border-radius: $borderRadius;
      aspect-ratio: 54 / 24;
      @include transition(box-shadow, background-color, color, border-color);

      &:hover,
      &:focus {
        background-color: $secondaryColor100;
      }
    }

    &-epuap {
      height: 24px;
      font-size: 0;
      background-image: url("#{$assetsPath}/images/epuap.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 75px 20px;
      border-radius: $borderRadius;
      aspect-ratio: 75 / 20;
      @include transition(box-shadow, background-color, color, border-color);

      &:hover,
      &:focus {
        background-color: $secondaryColor100;
      }
    }
  }

  &-main {
    padding: 16px 0;

    & > .container {
      display: grid;
      align-items: center;
      justify-content: center;
      gap: 16px;
      grid-template-columns: 32px 1fr 32px;

      @media #{$md-min-width} {
        grid-template-columns: 1fr 32px;
      }

      @media #{$lg-min-width} {
        grid-template-columns: 1fr 300px;
      }
    }

    &-burger {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      padding: 0;
      cursor: pointer;
      background-color: transparent;
      border: none;
      border-radius: $borderRadius;

      &:hover,
      &:focus {
        span {
          background-color: $secondaryColor80;

          &::before,
          &::after {
            background-color: $secondaryColor80;
          }
        }
      }

      span {
        position: relative;
        z-index: 1;
        display: block;
        width: 16px;
        height: 3px;
        background-color: $grayColor100;
        @include transition(box-shadow, background-color, color, border-color);

        &::before,
        &::after {
          content: "";
          position: absolute;
          left: 50%;
          z-index: 1;
          width: 100%;
          height: 100%;
          background-color: $grayColor100;
          translate: -50% 0;
          @include transition(box-shadow, background-color, color, border-color);
        }

        &::before {
          top: -6px;
        }

        &::after {
          top: 6px;
        }
      }

      @media #{$md-min-width} {
        display: none;
      }
    }

    &-home {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      color: $darkTextColor;
      @include transition(box-shadow, background-color, color, border-color, transform);

      @media #{$sm-min-width} {
        justify-content: start;
      }

      &:hover,
      &:focus {
        color: $secondaryColor80;

        @media #{$sm-max-width} {
          transform: translateY(-5px);
        }
      }
    }

    &-logo {
      width: auto;
      height: 100%;
      max-height: 36px;
      object-fit: contain;

      @media #{$sm-min-width} {
        max-height: 48px;
      }

      @media #{$lg-min-width} {
        max-height: 60px;
      }
    }

    &-site-name {
      display: block;
      text-transform: uppercase;
      text-wrap: balance;
      word-break: break-word;
      @include h400();

      @media #{$sm-max-width} {
        position: absolute;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        width: 1px;
        height: 1px;
        margin: -1px;
        padding: 0;
        white-space: nowrap;
        border-width: 0;
      }

      @media #{$lg-min-width} {
        @include h500();
      }
    }

    &-search {
      display: none;
      width: 100%;

      @media #{$lg-min-width} {
        display: block;
      }

      &-mobile {
        position: relative;
        width: var(--size);
        height: var(--size);
        font-size: 0;
        border-radius: $borderRadius;
        --size: 32px;
        @include transition(box-shadow, background-color, color, border-color);

        &::before {
          content: "";
          position: absolute;
          inset: 0;
          background-image: url("#{$assetsPath}/images/search.svg");
          background-repeat: no-repeat;
          background-position: center;
          background-size: 24px;
          border-radius: $borderRadius;
        }

        &:hover,
        &:focus {
          background-color: $secondaryColor80;

          &::before {
            background-image: url("#{$assetsPath}/images/search_white.svg");
          }
        }

        @media #{$lg-min-width} {
          display: none;
        }
      }
    }
  }

  &-nav {
    &-wrapper {
      @media #{$md-min-width} {
        padding: 0;
        border-top: 1px solid $grayColor10;
        border-bottom: 1px solid $grayColor10;
      }

      & > .container {
        display: flex;
        align-items: stretch;
        justify-content: center;
        gap: 12px;
      }
    }
  }
}

// ------------------CONTRAST------------------
.contrast {
  .header {
    &-options {
      color: $contrastYellowColor;
      background-color: $contrastBlackColor;

      &-socials {
        border-right-color: $contrastYellowColor;
      }

      &-social {
        border: 1px solid $contrastBlackColor;

        &:hover,
        &:focus {
          background-color: $contrastBlackColor;
          border-color: $contrastYellowColor;
        }
      }

      &-contact {
        color: $whiteColor100;
      }

      &-bip {
        border: 1px solid $contrastBlackColor;

        &:hover,
        &:focus {
          background-color: $contrastBlackColor;
          border-color: $contrastYellowColor;
        }
      }

      &-epuap {
        border: 1px solid $contrastBlackColor;

        &:hover,
        &:focus {
          background-color: $contrastBlackColor;
          border-color: $contrastYellowColor;
        }
      }
    }

    &-main {
      background-color: $contrastBlackColor;
      border-top: 1px solid $contrastYellowColor;

      &-burger {
        background-color: transparent;
        border: 1px solid $contrastBlackColor;

        &:hover,
        &:focus {
          border: 1px solid $contrastYellowColor;

          span {
            background-color: $contrastWhiteColor;

            &::before,
            &::after {
              background-color: $contrastWhiteColor;
            }
          }
        }

        span {
          background-color: $contrastYellowColor;

          &::before,
          &::after {
            background-color: $contrastYellowColor;
          }
        }
      }

      &-home {
        color: $contrastYellowColor;

        &:hover,
        &:focus {
          color: $contrastWhiteColor;
        }
      }

      &-search {
        &-mobile {
          &::before {
            background-image: url("#{$assetsPath}/images/search_white.svg");
          }

          &:hover,
          &:focus {
            background-color: $contrastYellowColor;

            &::before {
              background-image: url("#{$assetsPath}/images/search.svg");
              filter: brightness(0);
            }
          }
        }
      }
    }

    &-nav {
      &-wrapper {
        @media #{$md-min-width} {
          background-color: $contrastBlackColor;
          border-top-color: $contrastYellowColor;
          border-bottom: 1px solid $contrastYellowColor;
        }
      }
    }
  }
}
