$height: calculateRem(250px);
$lgMinHeight: calculateRem(351px);

.attractions-section-v3 {
  overflow: hidden;

  &-wrapper {
    position: relative;
    padding: 48px 16px;
    background-color: $whiteColor100;
    border-radius: $borderRadius;

    @media #{$sm-min-width} {
      padding: 48px;
    }
  }

  .slick {
    &-controls {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;

      & > * {
        flex-shrink: 0;
      }
    }

    &-slide {
      overflow: hidden;
    }

    &-dots {
      justify-content: center;
      width: 100%;
      margin-bottom: 12px;

      @media #{$lg-min-width} {
        position: absolute;
        top: 50%;
        left: calc(50% + 20px);
        flex-direction: column;
        width: max-content;
        margin-bottom: unset;
        translate: -50% -50%;
      }
    }

    &-pause-play {
      margin-left: 12px;
    }
  }

  &-list {
    position: relative;
    margin-bottom: 0;
    padding-left: 0;

    @media #{$lg-min-width} {
      min-height: $lgMinHeight;
    }
  }

  &-item {
    position: relative;
    display: none;
    flex-direction: column;
    height: 100%;
    -webkit-backface-visibility: hidden;

    &:first-child {
      display: flex !important;
      -webkit-backface-visibility: visible;
    }

    @media #{$lg-min-width} {
      gap: 136px;
      flex-direction: row;
      padding-top: 50px;
      padding-bottom: 50px;
      -webkit-backface-visibility: visible;
    }
  }

  &-image {
    width: 100%;
    height: 100%;
    min-height: $height;
    object-fit: cover;
    object-position: center;

    @media #{$lg-min-width} {
      min-height: $lgMinHeight;
      border-radius: $borderRadius;
    }

    &-wrapper {
      position: relative;
      flex-basis: 50%;
      width: 100%;
      aspect-ratio: 528 / 351;

      @media #{$lg-min-width} {
        border-radius: $borderRadius;
        translate: 40px 0;
      }

      &::before {
        @media #{$lg-min-width} {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: -1;
          width: 100%;
          height: 80%;
          background-image: $primaryColor100;
          border-radius: $borderRadius;
          translate: -40px 30px;
        }
      }
    }
  }

  &-content {
    flex-basis: 50%;
    padding-top: 24px;
    padding-bottom: 24px;
    color: $darkTextColor;
    background-color: $whiteColor100;
    border-radius: $borderRadius;

    @media #{$lg-min-width} {
      position: relative;
      top: 40px;
      padding: 0;
    }
  }

  &-link {
    display: grid;
    gap: 12px;
    color: $darkTextColor;

    @media #{$lg-max-width} {
      max-width: 100%;
      margin: 0;
      padding: 0;
    }

    &:hover,
    &:focus {
      text-decoration: underline;
      color: $darkTextColor;
    }
  }

  &-heading {
    margin-bottom: 4px;
    @include h500();

    @media #{$lg-min-width} {
      @include h600();
    }
  }

  &-text {
    color: $grayColor60;
    @include h200();

    @media #{$lg-min-width} {
      @include h300();
    }
  }
}

// ------------------CONTRAST------------------

.contrast {
  .attractions-section-v3 {
    &-wrapper {
      background-color: $contrastBlackColor;
      border: 1px solid $contrastYellowColor;
    }

    &-image {
      &-wrapper {
        @media #{$lg-min-width} {
          translate: unset;
        }

        &::before {
          @media #{$lg-min-width} {
            display: none;
          }
        }
      }
    }

    &-content {
      color: $contrastYellowColor;
      background-color: $contrastBlackColor;
    }

    &-link {
      color: $contrastYellowColor;

      &:hover,
      &:focus {
        color: $contrastWhiteColor;
      }
    }

    &-text {
      color: $contrastYellowColor;
    }
  }
}
