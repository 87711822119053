*,
*::after,
*::before {
  box-sizing: border-box;
}

* {
  margin: 0;
}

html {
  font-family: $fontFamily;
  -moz-tab-size: 4;
  tab-size: 4;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
}

body {
  margin: 0;
  color: $darkTextColor;
  @include bodyMd();

  &.contrast {
    color: $contrastYellowColor;
    background-color: $contrastBlackColor;
  }
}

:where(ol, ul) {
  margin: 0;
  padding: 0;
  list-style: none;
}

:where(img, picture, video, canvas, svg) {
  display: block;
  max-width: 100%;
  height: auto;
}

:where(video) {
  object-fit: cover;
}

:where(video, iframe) {
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto;
  border: none;
  aspect-ratio: 16 / 9;
}

video,
iframe {
  width: 100%;
  height: auto;
}

:where(input, textarea, input, select) {
  font: inherit;
}

:where(textarea) {
  field-sizing: content;
}

:where(h1, h2, h3, h4, h5, h6, p, a, span, label, legend) {
  word-break: break-word;
  overflow-wrap: break-word;
}

:where(button) {
  cursor: pointer;
}
