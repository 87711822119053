.cookies-message {
  &-container {
    position: fixed;
    bottom: 0;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    flex-wrap: wrap;
    width: 100%;
    padding: 10px 20px;
    font-size: 11px;
    line-height: 1.5;
    background-color: #E6E6E6;
  }

  &-text {
    text-align: center;
    color: #000000;
  }

  &-link {
    text-decoration: none;
    color: #B32B32;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  &-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    flex-wrap: wrap;
  }

  &-button {
    display: inline-block;
    padding: 5px 10px;
    cursor: pointer;
    text-decoration: none;
    font-size: 13px;
    line-height: 1.5;
    color: #E6E6E6;
    background-color: #000000;
    border: none;
    border-radius: 5px;

    &:hover,
    &:focus {
      color: #E6E6E6;
      background-color: #2C2C2C;
    }
  }
}

// ------------------CONTRAST------------------
.contrast {
  .cookies-message {
    &-container {
      background-color: $contrastBlackColor;
      border-top: 1px solid $contrastYellowColor;
    }

    &-text {
      color: $contrastYellowColor;
    }

    &-link {
      color: $contrastWhiteColor;
    }

    &-button {
      color: $contrastBlackColor;
      background-color: $contrastYellowColor;
      border: none;

      &:hover,
      &:focus {
        color: $contrastBlackColor;
        background-color: $contrastWhiteColor;
      }
    }
  }
}