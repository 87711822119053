.module-errors {
  .heading {
    margin-bottom: 12px;
  }
}

// ------------------CONTRAST------------------
.contrast {

}
