.isHome {
  .component {
    padding: 3rem 0;
  }
}

// ------------------CONTRAST------------------

.contrast {
  .isHome {
    & > .component {
      border-bottom: 2px solid $contrastYellowColor;
    }
  }
}