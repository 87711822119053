.module-organizations {
  &-search {
    &-form {

    }

    &-grid {
      display: flex;
      gap: 16px;
      flex-direction: column;

      @media #{$lg-min-width} {
        flex-direction: row;
      }
    }

    &-item {
      position: relative;

      &:first-child {
        flex-grow: 1;
      }
    }

    .btn {
      width: 100%;
      height: 100%;
    }
  }

  &-list {
    display: grid;
    gap: 32px;

    @media #{$lg-min-width} {
      justify-content: center;
      grid-template-columns: 1fr 1fr;
    }

    @media #{$xl-min-width} {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @media #{$xxl-min-width} {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }

  &-element {
    display: flex;
    gap: 4px;
    flex-direction: column;
    height: 100%;
    padding: 12px 24px;
    color: $darkTextColor;
    background-color: $whiteColor100;
    border-radius: $borderRadius;
    box-shadow: $shadowSm;
    @include transition(box-shadow, background-color, color, border-color);

    &:hover,
    &:focus {
      text-decoration: none;
      box-shadow: $shadowMd;
    }

    &-heading {
      @include h300();
    }

    &-text {
      @include bodyMd();
    }
  }
}

// ------------------CONTRAST------------------
.contrast {
  .module-organizations {
    &-element {
      color: $contrastYellowColor;
      background-color: $contrastBlackColor;
      border: 1px solid $contrastYellowColor;
      box-shadow: none;

      &:hover,
      &:focus {
        color: $contrastBlackColor;
        background-color: $contrastYellowColor;
        border-color: $contrastYellowColor;
        box-shadow: none;
      }
    }
  }
}
