.applications-section-v1 {
  position: relative;

  &-wrapper {
    padding: 48px 16px;
    background-color: $whiteColor100;
    border-radius: $borderRadius;

    @media #{$sm-min-width} {
      padding: 48px;
    }

    & > *:not(:last-child) {
      margin-bottom: 32px;
    }
  }

  .subheading {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 24px;
    @include h300();

    &::before {
      content: "";
      display: inline-block;
      width: var(--size);
      height: var(--size);
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      --size: 38px;
    }

    &.categories {
      &::before {
        background-image: url("#{$assetsPath}/images/material_business_center.svg");
      }
    }

    &.departments {
      &::before {
        background-image: url("#{$assetsPath}/images/material_groups.svg");
      }
    }
  }

  &-list {
    display: grid;
    gap: 24px;

    @media #{$md-min-width} {
      justify-content: center;
      grid-template-columns: 1fr 1fr;
    }

    @media #{$lg-min-width} {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  &-element {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    padding: 16px 32px;
    color: $darkTextColor;
    background-color: $whiteColor100;
    border-radius: $borderRadius;
    box-shadow: $shadowSm;
    @include h400();
    @include transition(box-shadow, background-color, color, border-color);

    &:hover,
    &:focus {
      text-decoration: none;
      box-shadow: $shadowMd;
    }
  }

  &-search {
    display: flex;
    gap: 16px;
    flex-direction: column;

    li {
      &:first-child {
        flex-grow: 1;
      }

      & > .btn {
        width: 100%;
      }
    }

    @media #{$sm-min-width} {
      align-items: center;
      flex-direction: row;
      flex-wrap: wrap;
    }

    label {
      position: absolute;
      height: 0;
      text-indent: -99999px;
    }

    .buttonSearch {
      position: absolute;
      right: 0;
      bottom: 0;
      margin-bottom: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

// ------------------CONTRAST------------------

.contrast {
  .applications-section-v1 {
    &-wrapper {
      background-color: $contrastBlackColor;
      border: 1px solid $contrastYellowColor;
    }

    .subheading {
      &::before {
        display: none;
      }

      &.categories {
        &::before {
          display: none;
        }
      }

      &.departments {
        &::before {
          display: none;
        }
      }
    }

    &-element {
      color: $contrastYellowColor;
      background-color: $contrastBlackColor;
      border: 1px solid $contrastYellowColor;
      box-shadow: none;

      &:hover,
      &:focus {
        color: $contrastBlackColor;
        background-color: $contrastYellowColor;
        border-color: $contrastYellowColor;
        box-shadow: none;
      }
    }
  }
}
