.video-news-list-v2 {
  &-list {
    display: grid;
    gap: 24px;
    grid-template-columns: 1fr;

    @media #{$md-min-width} {
      grid-template-columns: repeat(2, 1fr);
    }

    @media #{$xl-min-width} {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

// ------------------CONTRAST------------------

.contrast {
  .video-news-list-v2 {
  }
}