.sub-navigation {
  display: none;
  margin-bottom: 32px;

  @media #{$md-min-width} {
    margin-bottom: 64px;
  }

  &.opened {
    display: block;
  }

  &-toggle {
    position: relative;
    width: var(--size);
    height: var(--size);
    padding: 0;
    outline: none;
    font-size: 0;
    background-color: transparent;
    border: none;
    border-radius: $borderRadius;
    --size: 32px;
    @include transition(box-shadow, background-color, color, border-color);

    &:hover,
    &:focus {
      color: $grayColor100;
      background-color: $secondaryColor10;
    }

    &::before {
      content: "";
      position: absolute;
      inset: 0;
      transform: rotate(-90deg);
      transform-origin: center;
      background-image: url("#{$assetsPath}/images/expand.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      border-radius: $borderRadius;
      @include transition(transform);
    }

    &.opened {
      &::before {
        transform: rotate(0);
      }
    }
  }

  &-list {
    display: grid;
    gap: 16px;
    grid-template-columns: 1fr;

    @media #{$sm-min-width} {
      grid-template-columns: repeat(2, 1fr);
    }

    @media #{$lg-min-width} {
      grid-template-columns: repeat(3, 1fr);
    }

    @media #{$xl-min-width} {
      gap: 32px;
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &-item {
  }

  &-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 12px 24px;
    text-align: center;
    color: $grayColor100;
    background-color: $whiteColor100;
    border-radius: $borderRadius;
    box-shadow: $shadowSm;
    @include h300();
    @include transition(box-shadow, background-color, color, border-color);

    &:is(a, button) {
      &:hover,
      &:focus {
        text-decoration: none;
        box-shadow: $shadowMd;
      }
    }
  }
}

// ------------------CONTRAST------------------
.contrast {
  .sub-navigation {
    &-toggle {
      color: $contrastYellowColor;
      background-color: $contrastBlackColor;
      border: 1px solid $contrastYellowColor;

      &::before {
        filter: brightness(100);
      }

      &:hover,
      &:focus {
        color: $contrastBlackColor;
        background-color: $contrastYellowColor;
        border: 1px solid $contrastYellowColor;

        &::before {
          filter: brightness(0);
        }
      }
    }

    &-link {
      color: $contrastYellowColor;
      background-color: $contrastBlackColor;
      border: 1px solid $contrastYellowColor;
      box-shadow: none;

      &:hover,
      &:focus {
        color: $contrastBlackColor;
        background-color: $contrastYellowColor;
        border-color: $contrastYellowColor;
        box-shadow: none;
      }
    }
  }
}
