.alert {
  margin-top: 32px;
  padding: 16px;
  text-align: center;
  border: 1px solid $grayColor100;
  border-radius: $borderRadius;

  &::before {
    content: '';
    margin-right: 16px;
    vertical-align: middle;
    font-family: $fontIconFamily;
    font-size: 1.5em;
  }

  &-info {
    color: $infoColor100;
    background-color: $infoColor0;
    border-color: $infoColor100;

    &::before {
      content: '\f05a';
    }
  }

  &-success {
    color: $successColor100;
    background-color: $successColor0;
    border-color: $successColor100;

    &::before {
      content: '\f06a';
    }
  }

  &-error {
    color: $errorColor100;
    background-color: $errorColor0;
    border-color: $errorColor100;

    &::before {
      content: '\f06a';
    }
  }
}

// ------------------CONTRAST------------------
.contrast {
  .alert {
    color: $contrastYellowColor;
    background-color: $contrastBlackColor;
    border-color: $contrastYellowColor;
  }
}
