.banners-list-v4 {
  padding: 96px 0 48px;

  &-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 166px;

    .slick {
      &-slide {
        margin: 0 12px;

        @media #{$md-min-width} {
          margin: 0 26px;
        }
      }
    }
  }

  &-item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: $secondaryColor80;
    background-color: $whiteColor100;
    border: 1px solid transparent;
    border-radius: $borderRadius;
    -webkit-backface-visibility: hidden;
    @include h100();
    @include transition(box-shadow, background-color, color, border-color);

    &:is(a, button) {
      &:hover,
      &:focus {
        outline: none;
        text-decoration: none;
        border-color: $grayColor100;
      }
    }

    &:first-child {
      display: flex;
      -webkit-backface-visibility: visible;
    }
  }

  &-image {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: $borderRadius;
    aspect-ratio: 304 / 166;
    object-fit: cover;
  }
}

// ------------------CONTRAST------------------

.contrast {
  .banners-list-v4 {
    background-color: $contrastBlackColor;
    border-top: 1px solid $contrastYellowColor;

    &-item {
      color: $contrastYellowColor;
      background-color: $contrastBlackColor;
      border: 3px solid $contrastYellowColor;

      &:is(a) {
        &:hover,
        &:focus {
          color: $contrastBlackColor;
          background-color: $contrastWhiteColor;
          border-color: $contrastWhiteColor;
        }
      }
    }
  }
}
