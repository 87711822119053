:where(.badge) {
  display: inline-block;
  padding: 2px 12px;
  color: $darkTextColor;
  background-color: $grayColor5;
  border: 1px solid $grayColor40;
  border-radius: $roundedBorderRadius;
  @include bodySm();

  &:is(a, button) {
    @include transition(box-shadow, background-color, color, border-color);

    &:hover,
    &:focus {
      color: $darkTextColor;
      background-color: darken($grayColor5, 5%);
      border-color: darken($grayColor5, 10%);
    }
  }

  &.badge-md {
    padding: 6px 12px;
    @include bodyMd();
  }

  &.badge-yellow {
    color: $darkTextColor;
    background-color: $categoryYellowColor;
    border-color: darken($categoryYellowColor, 10%);

    &:is(a, button) {
      &:hover,
      &:focus {
        color: $darkTextColor;
        background-color: darken($categoryYellowColor, 5%);
        border-color: darken($categoryYellowColor, 10%);
      }
    }
  }

  &.badge-orange {
    color: $whiteColor100;
    background-color: $categoryOrangeColor;
    border-color: darken($categoryOrangeColor, 10%);

    &:is(a, button) {
      &:hover,
      &:focus {
        color: $whiteColor100;
        background-color: darken($categoryOrangeColor, 5%);
        border-color: darken($categoryOrangeColor, 10%);
      }
    }
  }

  &.badge-red {
    color: $whiteColor100;
    background-color: $categoryRedColor;
    border-color: darken($categoryRedColor, 10%);

    &:is(a, button) {
      &:hover,
      &:focus {
        color: $whiteColor100;
        background-color: darken($categoryRedColor, 5%);
        border-color: darken($categoryRedColor, 10%);
      }
    }
  }
}

// ------------------CONTRAST------------------
.contrast {
  :where(.badge) {
    color: $contrastYellowColor !important;
    background-color: $contrastBlackColor !important;
    border: 1px solid $contrastYellowColor !important;

    &:is(a, button) {
      &:hover,
      &:focus {
        color: $contrastBlackColor !important;
        background-color: $contrastYellowColor !important;
      }
    }
  }
}
