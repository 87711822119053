.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;

  &-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10000;
    width: var(--size);
    height: var(--size);
    border-radius: $borderRadius;
    --size: 32px;

    .fa {
      &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        line-height: 0;
        @include bodyMd();
      }
    }
  }

  &-dialog {
    margin: 32px auto 64px;
    padding: 32px;
    user-select: text;
    pointer-events: auto;
    background-color: $whiteColor100;
    border: 1px solid $grayColor10;
    border-radius: $borderRadius;
  }

  &-body {
    padding: 0;
    border: none;
  }

  &-content {
    border: none;
  }

  .article-text {
    :where(p) {
      margin-bottom: 0;

      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
  }
}

// ------------------CONTRAST------------------
.contrast {
  .modal {
    &-close {
      .fa {
        &::before {
        }
      }
    }

    &-dialog {
      background-color: $contrastBlackColor;
      border-color: $contrastYellowColor;
    }

    &-body {
    }

    &-content {
      background-color: $contrastBlackColor;
    }
  }
}
