.events-section-v1-counter {
  position: relative;
  margin-top: 64px;
  padding: 48px 0;

  @media #{$md-min-width} {
    margin-bottom: -80px !important;
    padding: 96px 0 140px;
  }

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    z-index: 0;
    background-image: $primaryColor80;
  }

  &-wrapper {
    position: relative;
  }

  .heading {
    color: $whiteColor100;
  }

  &-list {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    flex-wrap: wrap;
  }

  &-item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-width: var(--size);
    min-height: var(--size);
    padding: 12px 0;
    color: $grayColor100;
    background-color: $whiteColor100;
    border-radius: $borderRadius;
    --size: 80px;
    aspect-ratio: 1;
  }

  &-number {
    @include h500();
  }

  &-suffix {
    @include bodySm();
  }
}

// ------------------CONTRAST------------------
.contrast {
  .events-section-v1-counter {
    margin-top: 0;
    margin-bottom: -40px !important;

    &::before {
      display: none;
    }

    .heading {
      color: $contrastYellowColor;
    }

    &-item {
      color: $contrastYellowColor;
      background-color: $contrastBlackColor;
      border: 1px solid $contrastYellowColor;
    }
  }
}