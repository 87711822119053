.module-cms {
  & > *:not(:last-child) {
    margin-bottom: 32px;
  }

  &-heading {
    display: flex;
    align-items: center;
    gap: 12px;
  }
}

// ------------------CONTRAST------------------
.contrast {

}
