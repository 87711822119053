$menuLvl2Width: 40;

.main-menu {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1002;
  display: flex;
  visibility: hidden;
  gap: 12px;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  transform: translateX(-100%);
  background-color: $whiteColor100;

  @media #{$md-min-width} {
    position: static;
    visibility: unset;
    max-width: unset;
    transform: unset;
    background-color: unset;
  }

  &.is-open {
    visibility: visible;
    transform: translateX(0);
  }

  &.is-toggling {
    @include transition(transform, visibility);
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 8px;
    padding: 10px 16px;

    @media #{$md-min-width} {
      display: none;
    }
  }

  &-close {
    position: relative;
    width: var(--size);
    height: var(--size);
    padding: 0;
    cursor: pointer;
    background-color: transparent;
    border: none;
    border-radius: $borderRadius;
    --size: 32px;
    @include transition(box-shadow, background-color, color, border-color);

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 24px;
      height: 2px;
      background-color: $grayColor100;
      translate: -50% -50%;
    }

    &::before {
      rotate: -45deg;
    }

    &::after {
      rotate: 45deg;
    }

    &:hover,
    &:focus {
      background-color: $secondaryColor80;

      &::before,
      &::after {
        background-color: $whiteColor100;
      }
    }
  }

  &-container {
    overflow-y: auto;
    flex-grow: 1;

    @media #{$md-min-width} {
      overflow-y: unset;
    }
  }

  &-list {
    position: relative;
    display: flex;
    gap: 0;
    flex-direction: column;
    height: 100%;

    @media #{$md-min-width} {
      justify-content: center;
      column-gap: 12px;
      flex-direction: row;
      flex-wrap: wrap;
    }

    &.menu-lvl2,
    &.menu-lvl3 {
      position: absolute;
      top: 100%;
      display: none;
      gap: 0;
      flex-direction: column;
      flex-wrap: nowrap;
      height: auto;
      background-color: $whiteColor100;
      box-shadow: $shadowMd;

      .main-menu-link {
        text-align: left;
      }
    }

    &.menu-lvl2[aria-expanded="true"],
    &.menu-lvl3[aria-expanded="true"] {
      display: flex;
    }

    &.menu-lvl2 {
      top: calc(100% + 1px);
      left: 0;
      width: 100%;

      &.has-opened-list {
        width: $menuLvl2Width * 1%;
      }
    }

    &.menu-lvl3 {
      top: 0;
      bottom: 0;
      left: 100%;
      overflow-y: auto;
      justify-content: start;
      width: calc(calc(calc(100% - #{$menuLvl2Width  * 1%}) / #{$menuLvl2Width}) * 100);
      border-left: 1px solid $grayColor10;
    }
  }

  &-item {
    &:is(.show) {
      &:is(.menu-item-lvl1) {
        & > .main-menu-link {
          color: $secondaryColor80;
          background-color: $whiteColor100;
          box-shadow: $shadowMd;
        }
      }

      &:is(.menu-item-lvl2) {
        & > .main-menu-link {
          color: $secondaryColor80;
          background-color: $whiteColor100;
        }
      }
    }

    &:is(.extended) {
      @media #{$md-min-width} {
        & > .main-menu-link {
          padding-right: 36px;

          &::after {
            content: "";
            position: absolute;
            top: 50%;
            right: 8px;
            display: inline-block;
            flex-grow: 0;
            flex-shrink: 0;
            width: 20px;
            height: 20px;
            background-image: url("#{$assetsPath}/images/arrow_menu.svg");
            background-repeat: no-repeat;
            background-size: contain;
            translate: 0 -50%;
            @include transition(transform);
          }
        }

        & > .menu-item-lvl2::after {
          transform: rotate(90deg);
        }

        &.show > .menu-item-lvl1::after {
          transform: rotate(90deg);
        }

        &.show > .menu-item-lvl2::after {
          transform: rotate(0);
        }
      }
    }
  }

  &-link {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    gap: 4px;
    width: 100%;
    height: 100%;
    padding: 24px 14px;
    text-align: center;
    color: $darkTextColor;
    background-color: $whiteColor100;
    border: none;
    @include h200();
    @include transition(box-shadow, background-color, color, border-color);

    @media #{$md-min-width} {
      padding: 16px;
      @include h300();
    }

    &:is(a, button) {
      &:hover,
      &:focus {
        text-decoration: none;
        background-color: $secondaryColor10;
        box-shadow: none;

        &:is(.active) {
          color: $secondaryColor80;
        }
      }
    }

    &.active {
      color: $whiteColor100;
      background-color: $secondaryColor80;
    }
  }

  &-options {
    display: flex;
    gap: 24px;
    flex-direction: column;
    padding: 32px 16px;
    background-color: $grayColor100;

    @media #{$md-min-width} {
      display: none;
    }

    &-contacts {
      display: flex;
      gap: 16px;
      flex-direction: column;
    }

    &-contact {
      display: inline-flex;
      align-items: center;
      gap: 10px;
      color: $whiteColor100;
      @include h200();

      &::before {
        content: "";
        display: block;
        width: var(--size);
        height: var(--size);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        --size: 24px;
      }
    }

    &-mail {
      &::before {
        background-image: url("#{$assetsPath}/images/mail.svg");
      }
    }

    &-tel {
      &::before {
        background-image: url("#{$assetsPath}/images/tel.svg");
      }
    }

    &-socials {
      display: flex;
      gap: 16px;
      flex-wrap: wrap;
    }

    &-social {
      width: var(--size);
      height: var(--size);
      font-size: 0;
      background-repeat: no-repeat;
      background-position: center;
      border-radius: $borderRadius;
      --size: 32px;
      @include transition(box-shadow, background-color, color, border-color);

      &:is(a, button) {
        &:hover,
        &:focus {
          background-color: $secondaryColor80;
        }
      }
    }

    &-facebook {
      background-image: url("#{$assetsPath}/images/facebook.svg");
      background-size: 22px;
    }
  }

  &-shadow {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1001;
    visibility: hidden;
    width: 100%;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.7);
    @include transition(visibility, opacity);

    @media #{$md-min-width} {
      display: none;
    }

    &.is-open {
      visibility: visible;
      opacity: 1;
    }
  }
}

// ------------------CONTRAST------------------
.contrast {
  .main-menu {
    background-color: $contrastBlackColor;
    border-right: 1px solid $contrastYellowColor;

    @media #{$md-min-width} {
      border-right: none;
    }

    &-close {
      background-color: $contrastBlackColor;

      &::before,
      &::after {
        background-color: $contrastYellowColor;
      }

      &:hover,
      &:focus {
        background-color: $contrastYellowColor;

        &::before,
        &::after {
          background-color: $contrastBlackColor;
        }
      }
    }

    &-list {
      &.menu-lvl2,
      &.menu-lvl3 {
        background-color: $contrastBlackColor;
        box-shadow: none;
      }

      &.menu-lvl2 {
        border: 1px solid $contrastYellowColor;
      }

      &.menu-lvl3 {
        height: calc(100% + 1px);
        border-right: 1px solid $contrastYellowColor;
        border-bottom: 1px solid $contrastYellowColor;
        border-left-color: $contrastYellowColor;
      }
    }

    &-item {
      &:is(.show) {
        &:is(.menu-item-lvl1) {
          & > .main-menu-link {
            color: $contrastBlackColor;
            background-color: $contrastYellowColor;
            box-shadow: none;
          }
        }

        &:is(.menu-item-lvl2) {
          & > .main-menu-link {
            color: $contrastBlackColor;
            background-color: $contrastYellowColor;
            box-shadow: none;
          }
        }
      }

      &:is(.extended) {
        @media #{$md-min-width} {
          & > .main-menu-link {

            &::after {
              filter: brightness(100);
            }
          }

          &.show > .menu-item-lvl1::after {
            filter: brightness(0);
          }

          &.show > .menu-item-lvl2::after {
            filter: brightness(0);
          }
        }
      }
    }

    &-link {
      color: $contrastYellowColor;
      background-color: $contrastBlackColor;
      border-top: 1px solid $contrastYellowColor;
      border-bottom: 1px solid $contrastYellowColor;

      @media #{$md-min-width} {
        border: none;
      }

      &:is(a, button) {
        &:hover,
        &:focus {
          color: $contrastBlackColor;
          background-color: $contrastWhiteColor;
          box-shadow: none;

          &::after {
            filter: brightness(0) !important;
          }

          &:is(.active) {
            color: $contrastBlackColor;
          }
        }
      }

      &.active {
        color: $contrastBlackColor;
        background-color: $contrastYellowColor;
      }
    }

    &-options {
      background-color: $contrastBlackColor;
      border-top: 1px solid $contrastYellowColor;

      &-contact {
        color: $contrastWhiteColor;
      }

      &-mail {
        &::before {
          filter: brightness(100);
        }
      }

      &-tel {
        &::before {
          filter: brightness(100);
        }
      }

      &-social {
        &:is(a, button) {
          &:hover,
          &:focus {
            background-color: $contrastYellowColor;
          }
        }
      }
    }
  }
}
